import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { common } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid2';
import { Theme, useMediaQuery } from '@mui/material';

import SearchFilterBar, {
  QuickFiltersType,
} from 'src/components/SearchFilterBar';
import { useGetJobApplicationsCount } from 'src/pages/ProjectDetailsPage/api';

import messages from './messages';
import StyledTabs from '../StyledTabs/StyledTabs';
import StyledTab from '../StyledTab/StyledTab';
import JobApplicationsAll from '../JobApplicationsAll/JobApplicationsAll';
import JobApplicationsApplied from '../JobApplicationsApplied/JobApplicationsApplied';
import JobApplicationsAccepted from '../JobApplicationsAccepted/JobApplicationsAccepted';
import JobApplicationsBooked from '../JobApplicationsBooked/JobApplicationsBooked';
import JobApplicationsDenied from '../JobApplicationsDenied/JobApplicationsDenied';
import JobApplicationsDone from '../JobApplicationsDone/JobApplicationsDone';

const JobApplicationsComponents = [
  JobApplicationsAll,
  JobApplicationsApplied,
  JobApplicationsAccepted,
  JobApplicationsBooked,
  JobApplicationsDenied,
  JobApplicationsDone,
];

const COUNT_TYPES = [
  'all_count',
  'applied_count',
  'accepted_count',
  'booked_count',
  'denied_count',
  'done_count',
];

export default function MainJob({
  job,
  uuid,
  handleSelectCandidate,
  candidate,
  calendarfilters,
}: UCM.JobTypeProps) {
  const { formatMessage } = useIntl();

  const [tab, setTab] = useState(0);
  const [filters, setFilters] = useState<Record<string, string>>({});

  const quickFilters: Array<QuickFiltersType> = useRef([
    {
      autoFocus: false,
      id: 'quick_search',
      type: 'text' as QuickFiltersType['type'],
      placeholder: formatMessage(messages.jobSerchLabel),
    },
  ]).current;

  const { counts } = useGetJobApplicationsCount({
    jobUuid: uuid ?? '',
    types: COUNT_TYPES,
    filters,
    enabled: Boolean(uuid),
  });

  useEffect(() => {
    setFilters((prevState) => ({ ...prevState, ...calendarfilters }));
  }, [calendarfilters]);

  const changeTab = useCallback((_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    if (typeof handleSelectCandidate === 'function')
      handleSelectCandidate(null);
  }, []);

  // eslint-disable-next-line
  const handleSearch = useCallback((filters: Record<string, any>) => {
    setFilters(filters);
  }, []);

  const TabData = useMemo(
    () => [
      { label: formatMessage(messages.allTabLabel), badge: counts?.all_count },
      {
        label: formatMessage(messages.appliedTabLabel),
        badge: counts?.applied_count,
      },
      {
        label: formatMessage(messages.acceptedTabLabel),
        badge: counts?.accepted_count,
      },
      {
        label: formatMessage(messages.bookedTabLabel),
        badge: counts?.booked_count,
      },
      {
        label: formatMessage(messages.deniedTabLabel),
        badge: counts?.denied_count,
      },
      {
        label: formatMessage(messages.doneTabLabel),
        badge: counts?.done_count,
      },
    ],
    [counts],
  );

  const CurrentJobComponent = useMemo(() => {
    return JobApplicationsComponents[tab];
  }, [tab]);

  const large = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  return (
    <>
      <Stack
        display={'flex'}
        flexDirection={large ? 'row' : 'column'}
        justifyContent={large ? 'space-between' : undefined}
        alignItems={large ? 'center' : undefined}
        gap={2}
        px={2}
        pb={2}
        bgcolor={common.white}
      >
        <StyledTabs value={tab} onChange={changeTab}>
          {TabData.map((tabItem, index) => (
            <StyledTab
              key={index}
              label={tabItem.label}
              badge={tabItem?.badge}
            />
          ))}
        </StyledTabs>
        <Grid size={{ xs: 12, sm: 7, md: 5, lg: 3 }} alignSelf="flex-end">
          <SearchFilterBar fields={quickFilters} onSearch={handleSearch} />
        </Grid>
      </Stack>
      <CurrentJobComponent
        job={job}
        jobUuid={uuid!}
        filters={filters}
        candidate={candidate}
        handleSelectCandidate={handleSelectCandidate}
      />
    </>
  );
}
