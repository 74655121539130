import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { boolean, date, number, object, string } from 'yup';

import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

import {
  useGetUserAccount,
  useGetCityOptions,
  useGetCountryOptions,
  useGetEmailAvailable,
  useGetOccupationOptions,
  useGetLanguageLevelOptions,
  type UserAccountParamsType,
} from 'src/pages/GatekeeperPage/api';

import Dropdown from 'src/components/Dropdown';
import TextInput from 'src/components/TextInput';
import ConfirmModal from 'src/components/ConfirmModal';
import { DatePickerField } from 'src/components/DatePickerField';
// import CustomToggleButton from 'src/components/CustomToggleButton';
import { PencilAdornment } from 'src/utils/components';

import type { Moment } from 'moment';
import type { SelectChangeEvent } from '@mui/material';

import messages from './messages';
import { Styled } from './index.styled';
import globalMessages from 'src/messages';

const phoneCodeOptions = [
  { value: 49, label: '49 Germany' },
  { value: 93, label: '93 Afghanistan' },
  { value: 35818, label: '35818 Åland Islands' },
  { value: 355, label: '355 Albania' },
  { value: 213, label: '213 Algeria' },
  { value: 1684, label: '1684 American Samoa' },
  { value: 376, label: '376 Andorra' },
  { value: 244, label: '244 Angola' },
  { value: 1264, label: '1264 Anguilla' },
  { value: 1268, label: '1268 Antigua and Barbuda' },
  { value: 54, label: '54 Argentina' },
  { value: 374, label: '374 Armenia' },
  { value: 297, label: '297 Aruba' },
  { value: 61, label: '61 Australia' },
  { value: 43, label: '43 Austria' },
  { value: 994, label: '994 Azerbaijan' },
  { value: 37447, label: '37447 Azerbaijan' },
  { value: 37497, label: '37497 Azerbaijan' },
  { value: 1242, label: '1242 Bahamas' },
  { value: 973, label: '973 Bahrain' },
  { value: 880, label: '880 Bangladesh' },
  { value: 1246, label: '1246 Barbados' },
  { value: 375, label: '375 Belarus' },
  { value: 32, label: '32 Belgium' },
  { value: 501, label: '501 Belize' },
  { value: 229, label: '229 Benin' },
  { value: 1441, label: '1441 Bermuda' },
  { value: 975, label: '975 Bhutan' },
  { value: 591, label: '591 Bolivia' },
  { value: 5997, label: '5997 Bonaire' },
  { value: 5994, label: '5994 Bonaire' },
  { value: 5993, label: '5993 Bonaire' },
  { value: 387, label: '387 Bosnia and Herzegovina' },
  { value: 267, label: '267 Botswana' },
  { value: 47, label: '47 Bouvet Island' },
  { value: 55, label: '55 Brazil' },
  { value: 246, label: '246 British Indian Ocean Territory' },
  { value: 673, label: '673 Brunei Darussalam' },
  { value: 359, label: '359 Bulgaria' },
  { value: 226, label: '226 Burkina Faso' },
  { value: 257, label: '257 Burundi' },
  { value: 238, label: '238 Cabo Verde' },
  { value: 855, label: '855 Cambodia' },
  { value: 237, label: '237 Cameroon' },
  { value: 1, label: '1 Canada' },
  { value: 1345, label: '1345 Cayman Islands' },
  { value: 236, label: '236 Central African Republic' },
  { value: 235, label: '235 Chad' },
  { value: 56, label: '56 Chile' },
  { value: 86, label: '86 China' },
  { value: 6189164, label: '6189164 Christmas Island' },
  { value: 6189162, label: '6189162 Cocos (Keeling) Islands' },
  { value: 57, label: '57 Colombia' },
  { value: 269, label: '269 Comoros' },
  { value: 242, label: '242 Congo' },
  { value: 243, label: '243 Congo (DRC)' },
  { value: 682, label: '682 Cook Islands' },
  { value: 506, label: '506 Costa Rica' },
  { value: 225, label: '225 Côte d’Ivoire' },
  { value: 385, label: '385 Croatia' },
  { value: 53, label: '53 Cuba' },
  { value: 5999, label: '5999 Curaçao' },
  { value: 357, label: '357 Cyprus' },
  { value: 90392, label: '90392 Cyprus' },
  { value: 420, label: '420 Czech Republic' },
  { value: 45, label: '45 Denmark' },
  { value: 253, label: '253 Djibouti' },
  { value: 1767, label: '1767 Dominica' },
  { value: 1809, label: '1809 Dominican Republic' },
  { value: 1829, label: '1829 Dominican Republic' },
  { value: 1849, label: '1849 Dominican Republic' },
  { value: 593, label: '593 Ecuador' },
  { value: 20, label: '20 Egypt' },
  { value: 503, label: '503 El Salvador' },
  { value: 240, label: '240 Equatorial Guinea' },
  { value: 291, label: '291 Eritrea' },
  { value: 372, label: '372 Estonia' },
  { value: 268, label: '268 Eswatini' },
  { value: 251, label: '251 Ethiopia' },
  { value: 500, label: '500 Falkland Islands (Malvinas)' },
  { value: 298, label: '298 Faroe Islands' },
  { value: 679, label: '679 Fiji' },
  { value: 358, label: '358 Finland' },
  { value: 33, label: '33 France' },
  { value: 594, label: '594 French Guiana' },
  { value: 689, label: '689 French Polynesia' },
  { value: 262, label: '262 French Southern Territories' },
  { value: 241, label: '241 Gabon' },
  { value: 220, label: '220 Gambia' },
  { value: 995, label: '995 Georgia' },
  { value: 233, label: '233 Ghana' },
  { value: 350, label: '350 Gibraltar' },
  { value: 30, label: '30 Greece' },
  { value: 299, label: '299 Greenland' },
  { value: 1473, label: '1473 Grenada' },
  { value: 590, label: '590 Guadeloupe' },
  { value: 1671, label: '1671 Guam' },
  { value: 502, label: '502 Guatemala' },
  { value: 441481, label: '441481 Guernsey' },
  { value: 447781, label: '447781 Guernsey' },
  { value: 447839, label: '447839 Guernsey' },
  { value: 447911, label: '447911 Guernsey' },
  { value: 224, label: '224 Guinea' },
  { value: 245, label: '245 Guinea-Bissau' },
  { value: 592, label: '592 Guyana' },
  { value: 509, label: '509 Haiti' },
  { value: 672, label: '672 Heard Island and McDonald Islands' },
  { value: 3906698, label: '3906698 Holy See (Vatican City State)' },
  { value: 379, label: '379 Holy See (Vatican City State)' },
  { value: 504, label: '504 Honduras' },
  { value: 852, label: '852 Hong Kong' },
  { value: 36, label: '36 Hungary' },
  { value: 354, label: '354 Iceland' },
  { value: 91, label: '91 India' },
  { value: 62, label: '62 Indonesia' },
  { value: 98, label: '98 Iran (Islamic Republic of)' },
  { value: 964, label: '964 Iraq' },
  { value: 353, label: '353 Ireland' },
  { value: 441624, label: '441624 Isle of Man' },
  { value: 447524, label: '447524 Isle of Man' },
  { value: 447624, label: '447624 Isle of Man' },
  { value: 447924, label: '447924 Isle of Man' },
  { value: 972, label: '972 Israel' },
  { value: 39, label: '39 Italy' },
  { value: 1876, label: '1876 Jamaica' },
  { value: 81, label: '81 Japan' },
  { value: 441534, label: '441534 Jersey' },
  { value: 962, label: '962 Jordan' },
];

interface PreRegisterFormModalProps {
  isEdit: boolean;
  userUuid?: string;
  onCreate?: (payload: UserAccountParamsType) => void;
  onUpdate?: (
    payload: UserAccountParamsType & { candidateUuid: string },
  ) => void;
  triggerButton?: (
    handleOpen: (event: React.MouseEvent<HTMLElement>) => void,
  ) => React.ReactNode;
}

export default function PreRegisterFormModal({
  isEdit,
  onUpdate,
  onCreate,
  userUuid,
  triggerButton,
}: PreRegisterFormModalProps) {
  const { formatMessage } = useIntl();

  const [showMoreFields, setShowMoreFields] = useState(false);
  const [debouncedEmail, setDebouncedEmail] = useState<string>('');

  const { data: cityOptions } = useGetCityOptions();
  const { data: coutryOptions } = useGetCountryOptions();
  const { data: occupationOptions } = useGetOccupationOptions();
  const { data: germanLevelOptions } = useGetLanguageLevelOptions('de');
  const { data: englishLevelOptions } = useGetLanguageLevelOptions('en');
  const { data: userAccount, refetch: fetchUserAccount } = useGetUserAccount(
    userUuid ?? '',
    { enabled: false },
  );

  const initialValues: UserAccountParamsType = useMemo(
    () => ({
      email: userAccount?.email ?? '',
      lastName: userAccount?.lastName ?? '',
      mainCity: userAccount?.cityCode ?? '',
      german: userAccount?.germanLevel ?? '',
      firstName: userAccount?.firstName ?? '',
      english: userAccount?.englishLevel ?? '',
      dateOfBirth: userAccount?.birthDate ?? null,
      phoneNumber: userAccount?.phone.number ?? '',
      occupation: userAccount?.occupationCode ?? '',
      graduation: userAccount?.graduationYear ?? '',
      phoneCode: userAccount?.phone.callingCode ?? '',
      nationality: userAccount?.citizenshipCountryCode ?? '',
    }),
    [userAccount],
  );

  const formik = useFormik<UserAccountParamsType>({
    initialValues,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: object().shape({
      german: string(),
      english: string(),
      mainCity: string(),
      lastName: string(),
      firstName: string(),
      occupation: string(),
      phoneNumber: string().test(
        'phoneNumber-required-with-phoneCode',
        formatMessage(globalMessages.requiredField),
        function (value) {
          const { phoneCode } = this.parent;
          return (!value && !phoneCode) || (value && phoneCode);
        },
      ),
      phoneCode: string().test(
        'phoneCode-required-with-phoneNumber',
        formatMessage(globalMessages.requiredField),
        function (value) {
          const { phoneNumber } = this.parent;
          return (!value && !phoneNumber) || (value && phoneNumber);
        },
      ),
      nationality: string(),
      dateOfBirth: date().nullable(),
      workPermit: boolean().nullable(),
      email: string().email().required(),
      healthCertificate: boolean().nullable(),
      graduation: number()
        .nullable()
        .test(
          'is-future-year',
          formatMessage(messages.futureYearRequired),
          (value) => {
            if (!value) return true;
            const currentYear = new Date().getFullYear();
            return value > currentYear;
          },
        ),
    }),
    onSubmit: () => {},
  });

  const { values, errors, isValid } = formik;

  const { data: emailAvailable } = useGetEmailAvailable(debouncedEmail, {
    enabled: !!debouncedEmail && !errors.email && !isEdit,
  });

  const debouncedSetEmail = useCallback(
    debounce((value: string) => {
      setDebouncedEmail(value);
    }, 1000),
    [],
  );

  useEffect(() => {
    if (emailAvailable) {
      if (emailAvailable.registrationStatus !== 'available') {
        formik.setFieldError('email', formatMessage(emailAvailable.message));
      }
    }
  }, [emailAvailable, formik.setFieldError]);

  const toggleFields = useCallback(() => {
    setShowMoreFields((prevState) => !prevState);
  }, []);

  const handleClose = useCallback(() => {
    formik.resetForm();
    setShowMoreFields(false);
  }, [formik.resetForm]);

  const handleSubmit = useCallback(async () => {
    const errors = await formik.validateForm();
    const isValid = isEmpty(errors);

    if (!isValid) throw errors;
    handleClose();

    if (onCreate && !isEdit) {
      onCreate({ ...values });
    } else if (onUpdate && isEdit) {
      onUpdate({ ...values, candidateUuid: userUuid ?? '' });
    }
  }, [isEdit, userUuid, values, onCreate, onUpdate, formik.validateForm]);

  const onChangeEventValue = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<unknown>,
      name: keyof Partial<UserAccountParamsType>,
    ) => {
      const { value } = event.target;
      formik.setFieldValue(name, value);

      if (name === 'email') {
        debouncedSetEmail(value as string);
      }
    },
    [formik.setFieldValue],
  );

  const onChangeValueByName = useCallback(
    (name: string, value: string | number | Moment | null) => {
      formik.setFieldValue(name, value);
    },
    [formik.setFieldValue],
  );

  const isConfirmationRequired = useMemo(() => {
    const { email, ...otherValues } = formik.values;

    const isEmail = email.trim() !== '';

    const isAnyFieldNotEmpty = Object.values(otherValues).some(
      (value) => value != null && value !== '',
    );

    return isEmail && !isAnyFieldNotEmpty;
  }, [formik.values]);

  return (
    <ConfirmModal
      onClose={handleClose}
      onConfirm={handleSubmit}
      confirmationDialogMaxWidth="xs"
      disabled={!isValid || !values.email}
      confirmationRequired={isConfirmationRequired}
      confirmationMessage={
        isConfirmationRequired
          ? formatMessage(messages.confirmationMessage)
          : ''
      }
      onOpen={isEdit ? () => fetchUserAccount() : undefined}
      title={formatMessage(isEdit ? messages.editTitle : messages.createTitle)}
      triggerButton={(handleOpen) => {
        if (triggerButton != null) return triggerButton(handleOpen);

        return (
          <Button
            variant="outlined"
            onClick={handleOpen}
            startIcon={<AddOutlinedIcon color="primary" />}
          >
            {formatMessage(messages.addPreRegisterButton)}
          </Button>
        );
      }}
    >
      <TextInput
        name="email"
        value={values.email}
        label={formatMessage(messages.emailField)}
        onChange={(event) => onChangeEventValue(event, 'email')}
        error={!!errors.email}
        helperText={errors.email}
        EndIcon={PencilAdornment}
        color={values.email ? (errors.email ? 'error' : 'success') : undefined}
      />

      <Stack direction="row">
        <Grid container spacing={2} width={'100vw'} alignItems="end">
          <Grid size={4}>
            <Dropdown
              name="phoneCode"
              value={values.phoneCode}
              placeholder={formatMessage(messages.phoneCodeField)}
              options={phoneCodeOptions}
              error={!!errors.phoneCode}
              helperText={errors.phoneCode}
              onChange={(event) => onChangeEventValue(event, 'phoneCode')}
              renderSelectedValue={(value) => value.toString()}
            />
          </Grid>

          <Grid size={8}>
            <TextInput
              fullWidth
              name="phoneNumber"
              value={values.phoneNumber}
              label={formatMessage(messages.phoneNumberField)}
              onChange={(event) => onChangeEventValue(event, 'phoneNumber')}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
              EndIcon={PencilAdornment}
            />
          </Grid>
        </Grid>
      </Stack>

      <TextInput
        name="firstName"
        value={values.firstName}
        label={formatMessage(messages.firstNameField)}
        onChange={(event) => onChangeEventValue(event, 'firstName')}
        error={!!errors.firstName}
        helperText={errors.firstName}
        EndIcon={PencilAdornment}
      />

      <TextInput
        name="lastName"
        value={values.lastName}
        label={formatMessage(messages.lastNameField)}
        onChange={(event) => onChangeEventValue(event, 'lastName')}
        error={!!errors.lastName}
        helperText={errors.lastName}
        EndIcon={PencilAdornment}
      />

      <Styled.ToggleButton direction="row" spacing={1} onClick={toggleFields}>
        <Typography fontSize={16}>
          {formatMessage(messages.baseProfileText)}
        </Typography>
        {showMoreFields ? (
          <ArrowDropUpOutlinedIcon />
        ) : (
          <ArrowDropDownOutlinedIcon />
        )}
      </Styled.ToggleButton>

      {showMoreFields && (
        <>
          <Stack>
            <Dropdown
              name="german"
              value={values.german}
              placeholder={formatMessage(messages.germanField)}
              options={germanLevelOptions}
              error={!!errors.german}
              helperText={errors.german}
              onChange={(event) => onChangeEventValue(event, 'german')}
            />
          </Stack>

          <Stack>
            <Dropdown
              name="english"
              value={values.english}
              placeholder={formatMessage(messages.englishField)}
              options={englishLevelOptions}
              error={!!errors.english}
              helperText={errors.english}
              onChange={(event) => onChangeEventValue(event, 'english')}
            />
          </Stack>

          <Stack>
            <DatePickerField
              name="dateOfBirth"
              value={values.dateOfBirth}
              label={formatMessage(messages.dateOfBirthField)}
              onChange={(value) => onChangeValueByName('dateOfBirth', value)}
            />
          </Stack>

          <Stack>
            <Dropdown
              name="occupation"
              value={values.occupation}
              placeholder={formatMessage(messages.occupationField)}
              options={occupationOptions}
              error={!!errors.occupation}
              helperText={errors.occupation}
              onChange={(event) => onChangeEventValue(event, 'occupation')}
            />
          </Stack>

          <Stack>
            <TextInput
              name="graduation"
              value={values.graduation}
              label={formatMessage(messages.graduationField)}
              onChange={(event) => onChangeEventValue(event, 'graduation')}
              error={!!errors.graduation}
              helperText={errors.graduation}
              EndIcon={PencilAdornment}
            />
          </Stack>

          <Stack>
            <Dropdown
              name="mainCity"
              value={values.mainCity}
              placeholder={formatMessage(messages.mainCityField)}
              options={cityOptions}
              error={!!errors.mainCity}
              helperText={errors.mainCity}
              onChange={(event) => onChangeEventValue(event, 'mainCity')}
            />
          </Stack>

          <Stack>
            <Dropdown
              name="nationality"
              value={values.nationality}
              placeholder={formatMessage(messages.nationalityField)}
              options={coutryOptions}
              error={!!errors.nationality}
              helperText={errors.nationality}
              onChange={(event) => onChangeEventValue(event, 'nationality')}
            />
          </Stack>

          {/* <Stack pb={1} spacing={3}>
              <CustomToggleButton
                label={formatMessage(messages.workPermitLabel)}
                name="workPermit"
                value={values.workPermit}
                onChange={onChangeValueByName}
                isError={!!errors.workPermit}
                justifyContent="space-between"
              />

              <CustomToggleButton
                label={formatMessage(messages.healthCertificateLabel)}
                name="healthCertificate"
                value={values.healthCertificate}
                onChange={onChangeValueByName}
                isError={!!errors.healthCertificate}
                justifyContent="space-between"
              />
            </Stack> */}
        </>
      )}
    </ConfirmModal>
  );
}
