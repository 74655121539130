// TODO
// Currently the signle date picker and multi date picker are separate due time constraint
// I will later on merge it as one component. Because we need to release

// Need to merge with src/components/DatePickerField/index.tsx

import { useState, useMemo, useCallback } from 'react';
import { Moment } from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  MobileDatePicker,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useIntl } from 'react-intl';
import { size } from 'lodash';
import sortBy from 'lodash/sortBy';
import messages from './messages';

const InputProps = { endAdornment: <TodayOutlinedIcon color="primary" /> };

type MultiDatePickerFieldProps = {
  label?: string;
  error?: boolean;
  helperText?: string;
  onChange?: (dates: Moment[]) => void;
  variant?: 'standard' | 'filled' | 'outlined';
};

export const MultiDatePickerField = ({
  label,
  onChange,
  helperText,
  error,
  variant = 'standard',
}: MultiDatePickerFieldProps) => {
  const { formatMessage } = useIntl();
  const [selectedDates, setSelectedDates] = useState<Moment[]>([]);

  const handleLabel = useMemo(() => {
    const selectedDatesLength = size(selectedDates);
    if (selectedDatesLength === 0) return label;
    return selectedDatesLength === 1
      ? `${selectedDatesLength} ${formatMessage(messages.dayLabel)}`
      : `${selectedDatesLength} ${formatMessage(messages.daysLabel)}`;
  }, [label, selectedDates]);

  // Handle the click event for day selection
  const handleDayClick = useCallback(
    (day: Moment) => {
      setSelectedDates((prevSelectedDates) => {
        const isSelected = prevSelectedDates.some((selected) =>
          day.isSame(selected, 'day'),
        );
        const newSelectedDates = isSelected
          ? prevSelectedDates.filter((selected) => !day.isSame(selected, 'day')) // Remove if already selected
          : [...prevSelectedDates, day]; // Add if not selected

        const sortedDates = sortBy(newSelectedDates, (date) => +date);
        onChange?.(sortedDates);
        return sortedDates;
      });
    },
    [onChange],
  );

  // Render the individual day in the calendar
  const handleRenderDay = useCallback(
    (props: PickersDayProps<Moment>) => {
      const isSelectedDay = selectedDates.some((selectedDate) =>
        selectedDate.isSame(props.day, 'day'),
      );
      return (
        <PickersDay
          {...props}
          selected={isSelectedDay}
          onDaySelect={() => handleDayClick(props.day)}
        />
      );
    },
    [selectedDates, handleDayClick],
  );

  const slotProps = useMemo(
    () => ({
      textField: {
        error,
        variant,
        helperText,
        sx: { width: '100%' },
        label: handleLabel,
        InputProps: InputProps,
      },
    }),
    [helperText, error, variant, handleLabel],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileDatePicker
        value={null} // Ensuring it's treated as a multi-date picker
        reduceAnimations
        slotProps={slotProps}
        slots={{ day: handleRenderDay }}
      />
    </LocalizationProvider>
  );
};
