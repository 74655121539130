import React, { useState, useCallback, useEffect, type ReactNode } from 'react';

import { useIntl } from 'react-intl';
import styled from '@mui/material/styles/styled';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import messages from './messages';
import DialogContent from '@mui/material/DialogContent';

interface ConfirmDialogProps {
  title?: string;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClose?: () => void;
  message?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  children?: (
    openDialog: (
      event: React.MouseEvent,
      message?: string,
      title?: string,
    ) => void,
  ) => ReactNode;
}

const Wrapper = styled('div')`
  display: inline-block;
`;

export default function ConfirmationDialog({
  title,
  message,
  onConfirm,
  onClose,
  maxWidth,
  children,
}: ConfirmDialogProps) {
  const { formatMessage } = useIntl();

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    setDialogOpen(false);
  }, [open]);

  const handleClose = useCallback(() => {
    if (onClose) onClose();
    setDialogOpen(false);
  }, [onClose]);

  const handleConfirm = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      let close: boolean | void = true;

      try {
        close = await onConfirm(event);
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
        close = false;
      }

      if (close !== false) handleClose();
    },
    [onConfirm, handleClose],
  );

  const preventEventBubbling = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  return (
    <Wrapper onClick={preventEventBubbling}>
      {typeof children === 'function' &&
        children((_: React.MouseEvent) => setDialogOpen(true))}

      <Dialog open={dialogOpen} maxWidth={maxWidth} onClose={handleClose}>
        <DialogTitle fontSize={16}>
          {title ?? formatMessage(messages.title)}
        </DialogTitle>

        {message && <DialogContent>{message}</DialogContent>}

        <DialogActions>
          <Button
            id="cancelButton"
            color="secondary"
            variant="text"
            name="cancelButton"
            onClick={handleClose}
          >
            {formatMessage(messages.cancelButtonLabel)}
          </Button>

          <Button
            id="confirmButton"
            color="secondary"
            name="confirmButton"
            onClick={handleConfirm}
          >
            {formatMessage(messages.confirmButtonLabel)}
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
}
