import { useMemo } from 'react';
import { Moment } from 'moment';

import { MobileDatePicker } from '@mui/x-date-pickers';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';

type DatePickerFieldProps = {
  name?: string;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  helperText?: string;
  value?: Moment | null;
  onChange?: (date: Moment | null) => void;
  variant?: 'standard' | 'filled' | 'outlined';
};

const InputProps = { endAdornment: <TodayOutlinedIcon /> };

export const DatePickerField = ({
  name,
  value,
  label,
  error,
  onChange,
  helperText,
  disabled = false,
  variant = 'standard',
  ...props
}: DatePickerFieldProps) => {
  const slotProps = useMemo(
    () => ({
      textField: {
        error,
        variant,
        helperText,
        fullWidth: true,
        InputProps: InputProps,
      },
    }),
    [helperText, error, variant],
  );

  return (
    <MobileDatePicker
      {...props}
      name={name}
      value={value}
      label={label}
      onChange={onChange}
      reduceAnimations
      disabled={disabled}
      slotProps={slotProps}
    />
  );
};
