import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { date, number, object } from 'yup';

import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

import { locale } from 'src/config';
import TextInput from 'src/components/TextInput';
import ConfirmModal from 'src/components/ConfirmModal';
import { checkNumberTyping } from 'src/utils/standards';
import { DatePickerField } from 'src/components/DatePickerField';
import {
  useCreateQuickApplyUser,
  useGetCandidates,
} from 'src/pages/ProjectDetailsPage/api';
import { ACCEPT_REASON } from 'src/pages/ProjectDetailsPage/api/queries/useGetAcceptReason';
import AcceptReasonModal from 'src/pages/ProjectDetailsPage/components/JobDetails/components/AcceptReasonModal/AcceptReasonModal';

import type { Moment } from 'moment';
import type { SelectChangeEvent } from '@mui/material/Select';

import messages from './messages';

const checkParam = { locale };

interface QuickApplyUserFormProps {
  job: UCM.JobType;
}

interface QuickApplyUserFormParamsType {
  userId: string;
  userName: string;
  userEmail: string;
  shiftDate: Moment | null;
}

export function QuickApplyUserForm({ job }: QuickApplyUserFormProps) {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const [debouncedUserId, setDebouncedUserId] = useState('');
  const [validateOnChange, setValidateOnChange] = useState(false);

  const { data: candidate, isFetching: isFetchingUser } = useGetCandidates(
    { id: debouncedUserId },
    { enabled: !!debouncedUserId },
  );
  const { mutate: quickApplyUser, isPending: isApplyingUser } =
    useCreateQuickApplyUser();

  const initialValues: QuickApplyUserFormParamsType = useMemo(
    () => ({
      userId: '',
      userUuid: '',
      userName: '',
      userEmail: '',
      shiftDate: null,
    }),
    [],
  );

  const formik = useFormik<QuickApplyUserFormParamsType>({
    initialValues,
    enableReinitialize: true,
    validateOnChange: validateOnChange,
    validationSchema: object().shape({
      userId: number().required().integer().min(0),
      ...(job.isFlex && { shiftDate: date().required() }),
    }),
    onSubmit: () => {},
  });

  const { errors, values, setFieldValue, resetForm } = formik;

  useEffect(() => {
    if (!debouncedUserId) {
      resetForm();
      return;
    }

    if (isFetchingUser) return;

    const fields = {
      userName: candidate?.userName ?? formatMessage(messages.userNotFound),
      userUuid: candidate?.userUuid ?? formatMessage(messages.userNotFound),
      userEmail: candidate?.userEmail ?? formatMessage(messages.userNotFound),
    };

    setFieldValue('userName', fields.userName);
    setFieldValue('userUuid', fields.userUuid);
    setFieldValue('userEmail', fields.userEmail);
  }, [candidate, debouncedUserId, isFetchingUser]);

  const debouncedSetUserId = useCallback(
    debounce((value: string) => {
      setDebouncedUserId(value);
    }, 1000),
    [],
  );
  const onChangeShiftDate = useCallback(
    (value: Moment | null, name: string) => {
      setFieldValue(name, value);
    },
    [],
  );

  const onChangeEventValue = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<unknown>,
      name: keyof QuickApplyUserFormParamsType,
    ) => {
      const { value } = event.target as { value: string };

      if (checkNumberTyping(value, checkParam)) {
        setFieldValue(name, value);
        debouncedSetUserId(value);
      }
    },
    [],
  );

  const handleClose = useCallback(() => {
    setValidateOnChange(false);
    setOpenApprove(false);
    setDebouncedUserId('');
    setOpen(false);
    resetForm();
  }, []);

  const handleOpenApproveChange = useCallback(() => {
    setOpenApprove((prevValue) => !prevValue);
  }, []);

  const handleSubmit = useCallback(
    async (isApplyAccept: boolean, reason: string | null) => {
      setValidateOnChange(true);

      const errors = await formik.validateForm();
      const isValid = isEmpty(errors);

      if (!isValid || !candidate?.userUuid) return false;

      return new Promise<boolean>((resolve) => {
        quickApplyUser(
          {
            shiftUuid: null,
            jobUuid: job.uuid,
            reasonCode: reason,
            shiftDate: values.shiftDate,
            applyAndAccept: isApplyAccept,
            candidateUuid: candidate.userUuid,
          },
          {
            onSuccess: () => {
              handleClose();
              resolve(true);
            },
            onError: () => {
              if (isApplyAccept) setOpenApprove(false);
              resolve(false);
            },
          },
        );
      });
    },
    [candidate?.userUuid, job.uuid, values.shiftDate, handleClose],
  );

  return (
    <ConfirmModal
      open={open}
      onClose={handleClose}
      title={formatMessage(messages.title)}
      disabled={!candidate || isApplyingUser}
      loading={isFetchingUser || isApplyingUser}
      onConfirm={() => handleSubmit(false, null)}
      submitTextButton={formatMessage(messages.applyButtonLabel)}
      triggerButton={() => {
        return (
          <IconButton onClick={() => setOpen(true)}>
            <PersonAddAltOutlinedIcon />
          </IconButton>
        );
      }}
      renderExtraButton={
        <Button
          onClick={handleOpenApproveChange}
          disabled={!candidate || isApplyingUser}
        >
          {formatMessage(messages.applyAndAcceptButtonLabel)}
        </Button>
      }
    >
      <Typography>{formatMessage(messages.subtitle)}</Typography>

      <Stack spacing={3}>
        <TextInput
          autoFocus
          name="userId"
          value={values.userId}
          error={!!errors.userId}
          onChange={(event) => onChangeEventValue(event, 'userId')}
          helperText={errors.userId ?? ''}
          label={formatMessage(messages.userIdLabel)}
        />

        <TextInput
          disabled
          name="userEmail"
          value={values.userEmail}
          error={!!errors.userEmail}
          onChange={formik.handleChange}
          helperText={errors.userEmail ?? ''}
          label={formatMessage(messages.userEmailLabel)}
        />

        <TextInput
          disabled
          name="userName"
          value={values.userName}
          error={!!errors.userName}
          onChange={formik.handleChange}
          helperText={errors.userName ?? ''}
          label={formatMessage(messages.userNameLabel)}
        />

        {job.isFlex && (
          <DatePickerField
            name="shiftDate"
            value={values.shiftDate}
            error={!!errors.shiftDate}
            onChange={(value) => onChangeShiftDate(value, 'shiftDate')}
            helperText={errors.shiftDate ?? ''}
            label={formatMessage(messages.shiftDateLabel)}
          />
        )}
      </Stack>

      <AcceptReasonModal
        open={openApprove}
        onConfirm={(reason) => handleSubmit(true, reason)}
        onClose={handleOpenApproveChange}
        enabledFetch={Boolean(candidate)}
        type={ACCEPT_REASON.ACCEPTANCE_APPLICATION}
        modalLabel={formatMessage(messages.acceptModalLabel)}
      />
    </ConfirmModal>
  );
}
