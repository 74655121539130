import React, { useCallback, useRef } from 'react';

import { useIntl } from 'react-intl';
import { Property } from 'csstype';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import styled from '@mui/material/styles/styled';
import TableFooter from '@mui/material/TableFooter';
import LoaderIndicator from 'src/components/LoaderIndicator';
import type { DefaultTheme } from '@mui/styles';

import SearchFilterBar, {
  type FilterStateType,
  type QuickFiltersType,
} from 'src/components/SearchFilterBar';

import { Styled } from './index.styled';
import messages from './messages';

const ROWS_PER_PAGE = 35;
const ROWS_PER_PAGE_OPTIONS = [ROWS_PER_PAGE];

export interface ColumnType<T> {
  id: keyof T | string;
  width?: Property.Width;
  whiteSpace?: Property.WhiteSpace;
  label: string | React.ReactNode;
  align?: 'right' | 'left' | 'center';
  render?: (
    value: T[keyof T] | {},
    row: T,
    index: number,
  ) => string | React.ReactNode;
}

interface DataTableProps<T extends { id?: string }> {
  loading?: boolean;
  className?: string;
  pagination?: UCM.MetaType;
  rows?: Array<T> | null;
  columns: Array<ColumnType<T>>;
  quickFilters?: Array<QuickFiltersType>;
  onSearch?: (filters: FilterStateType) => void;
  onPaginate?: (event: unknown, newPage: number) => void;
  toolbar?: React.ReactNode;
  enabledFooter?: boolean;
  pressRow?: (row: T | null) => void;
  checkActiveKey?: string;
  activeRow?: T | null;
  scrollToPosition?: number;
}

export default function DataTable<T extends { id?: string }>({
  rows,
  columns,
  onSearch,
  toolbar,
  className,
  pagination,
  onPaginate,
  quickFilters,
  enabledFooter = true,
  loading = false,
  checkActiveKey,
  activeRow,
  pressRow,
  scrollToPosition,
}: DataTableProps<T>) {
  const { formatMessage } = useIntl();
  const { page, totalRows } = resolvePagination(pagination);

  const handlePressRow = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, row: T) => {
      event.stopPropagation();
      if (pressRow) {
        const newElement =
          getNestedValue(row) !== getNestedValue(activeRow) ? row : null;
        pressRow(newElement);

        if (scrollToPosition) {
          window.scrollTo(0, scrollToPosition);
        }
      }
    },
    [activeRow, pressRow, scrollToPosition],
  );

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      if (onPaginate) onPaginate(event, newPage);
    },
    [onPaginate],
  );

  const getNestedValue = useCallback(
    // eslint-disable-next-line
    (obj?: Record<string, any> | null) => {
      if (!obj || !checkActiveKey) return null;

      if (checkActiveKey) {
        return checkActiveKey.split('.').reduce((acc, key) => acc?.[key], obj);
      }
    },
    [checkActiveKey],
  );

  const tableCellSx = useRef((theme: DefaultTheme) => ({
    borderBottom: 0,
    backgroundColor: theme.palette.common.white,
  })).current;

  const renderTableContent = () => {
    if (!rows || (rows.length === 0 && !loading)) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={columns.length} sx={tableCellSx}>
            <Box
              height={240}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {formatMessage(messages.noDataMessage)}
            </Box>
          </TableCell>
        </TableRow>
      );
    }

    return rows.map((row, index) => {
      const isActive = activeRow
        ? getNestedValue(row) === getNestedValue(activeRow)
        : false;

      return (
        <Styled.Row
          key={row.id ?? index}
          onClick={(event) => handlePressRow(event, row)}
          sx={{
            cursor: pressRow ? 'pointer' : 'default',
            ...(isActive
              ? { backgroundColor: '#C2E8FF !important' }
              : undefined),
          }}
        >
          {columns.map((column) => {
            const value = column.id in row ? row[column.id as keyof T] : {};

            return (
              <Styled.Cell
                key={String(column.id)}
                align={column.align}
                sx={{ width: column.width, whiteSpace: column.whiteSpace }}
              >
                {column.render
                  ? column.render(value, row, index)
                  : (value as string)}
              </Styled.Cell>
            );
          })}
        </Styled.Row>
      );
    });
  };

  return (
    <Styled.Container className={className}>
      {loading && <TableLoader />}

      <Table stickyHeader style={{ flex: 1 }}>
        <TableHead>
          {onSearch && quickFilters && (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Grid container justifyContent="flex-end">
                  <Grid size={{ xs: 12, sm: 7, md: 5, lg: 3, xl: 3 }}>
                    <SearchFilterBar
                      fields={quickFilters}
                      onSearch={onSearch}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          )}

          {toolbar && (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Grid container>{toolbar}</Grid>
              </TableCell>
            </TableRow>
          )}

          <TableRow>
            {columns.map((column) => (
              <TableCell key={String(column.id)} align={column.align}>
                {column.label || ''}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>{renderTableContent()}</TableBody>

        {enabledFooter && rows && rows.length !== 0 ? (
          <TableFooter>
            <TableRow>
              <Styled.Pagination
                page={page}
                count={totalRows}
                rowsPerPage={ROWS_PER_PAGE}
                onPageChange={handleChangePage}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              />
            </TableRow>
          </TableFooter>
        ) : null}
      </Table>
    </Styled.Container>
  );
}

export function resolvePagination(pagination: UCM.MetaType | undefined) {
  const page = pagination?.currentPage ? pagination.currentPage - 1 : 0;
  const totalRows = pagination?.totalData || 0;
  return { page, totalRows };
}

const LoaderWrapper = styled(Box)(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 10,
  opacity: 0.6,
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'fixed',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.common.white,
}));

const TableLoader = () => {
  return (
    <LoaderWrapper>
      <LoaderIndicator />
    </LoaderWrapper>
  );
};
