import React, { useCallback, useRef } from 'react';

import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import ListItem from '@mui/material/ListItem';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { DefaultTheme } from '@mui/styles/defaultTheme';

// Inactive SVGs
import { ReactComponent as UcmLogoIcon } from 'src/images/UcmLogo.svg';
import { ReactComponent as FineIcon } from 'src/images/DrawerIcons/Fine.svg';
import { ReactComponent as HomeIcon } from 'src/images/DrawerIcons/Home.svg';
import { ReactComponent as GatekeeperIcon } from 'src/images/DrawerIcons/Door.svg';
import { ReactComponent as SetupIcon } from 'src/images/DrawerIcons/Setup.svg';
import { ReactComponent as LogoutIcon } from 'src/images/DrawerIcons/Logout.svg';
import { ReactComponent as SearchIcon } from 'src/images/DrawerIcons/Search.svg';
import { ReactComponent as ProjectIcon } from 'src/images/DrawerIcons/Project.svg';
import { ReactComponent as StudentIcon } from 'src/images/DrawerIcons/Student.svg';
import { ReactComponent as ContractIcon } from 'src/images/DrawerIcons/Contract.svg';
import { ReactComponent as ConditionIcon } from 'src/images/DrawerIcons/Condition.svg';
import { ReactComponent as TimeTrackingIcon } from 'src/images/DrawerIcons/TimeTracking.svg';
import { ReactComponent as CancellationIcon } from 'src/images/DrawerIcons/Cancellation.svg';
import { ReactComponent as VerificationIcon } from 'src/images/DrawerIcons/Verification.svg';

// Active SVGs
import { ReactComponent as ActiveFineIcon } from 'src/images/DrawerIcons/ActiveFine.svg';
import { ReactComponent as ActiveHomeIcon } from 'src/images/DrawerIcons/ActiveHome.svg';
import { ReactComponent as ActiveSetupIcon } from 'src/images/DrawerIcons/ActiveSetup.svg';
import { ReactComponent as ActiveSearchIcon } from 'src/images/DrawerIcons/ActiveSearch.svg';
import { ReactComponent as ActiveProjectIcon } from 'src/images/DrawerIcons/ActiveProject.svg';
import { ReactComponent as ActiveStudentIcon } from 'src/images/DrawerIcons/ActiveStudent.svg';
import { ReactComponent as ActiveGatekeeperIcon } from 'src/images/DrawerIcons/ActiveDoor.svg';
import { ReactComponent as ActiveContractIcon } from 'src/images/DrawerIcons/ActiveContract.svg';
import { ReactComponent as ActiveConditionIcon } from 'src/images/DrawerIcons/ActiveCondition.svg';
import { ReactComponent as ActiveTimeTrackingIcon } from 'src/images/DrawerIcons/ActiveTimeTracking.svg';
import { ReactComponent as ActiveCancellationIcon } from 'src/images/DrawerIcons/ActiveCancellation.svg';
import { ReactComponent as ActiveVerificationIcon } from 'src/images/DrawerIcons/ActiveVerification.svg';

import useMessenger from 'src/utils/hooks/useMessenger';
import { removeAccessToken } from 'src/utils/hooks/useAuthentication';
import NavTabs, { type NavTabProps } from 'src/components/NavTabs';

import messages from './messages';

export const UnderConstructionMessage = ({ text }: { text?: string }) => (
  <div>
    Oops! The "{text?.toUpperCase()}" feature is still under construction.
    <br />
    We're working hard to bring it to you soon. Thanks for your patience!
  </div>
);

const DRAWER_WIDTH = 70;

const useStyles = makeStyles((theme) => ({
  appBar: {
    paddingLeft: DRAWER_WIDTH,
  },
  drawer: {
    '& .MuiDrawer-paper': {
      alignItems: 'center',
    },
  },
  listItemIcon: {
    justifyContent: 'center',
  },
  content: {
    marginLeft: DRAWER_WIDTH,
    padding: theme.spacing(3),
    paddingTop: theme.spacing(12),
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
  },
}));

interface MenuItemType {
  url: string;
  name: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  activeIcon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

export const menuItems: Array<MenuItemType> = [
  {
    name: 'home',
    icon: HomeIcon,
    activeIcon: ActiveHomeIcon,
    url: '/home',
  },
  {
    name: 'search',
    icon: SearchIcon,
    activeIcon: ActiveSearchIcon,
    url: 'disabled',
  },
  {
    name: 'projects',
    icon: ProjectIcon,
    activeIcon: ActiveProjectIcon,
    url: '/projects',
  },
  {
    name: 'cancellations',
    icon: CancellationIcon,
    activeIcon: ActiveCancellationIcon,
    url: 'disabled',
  },
  {
    name: 'students',
    icon: StudentIcon,
    activeIcon: ActiveStudentIcon,
    url: 'disabled',
  },
  {
    name: 'verification',
    icon: VerificationIcon,
    activeIcon: ActiveVerificationIcon,
    url: 'disabled',
  },
  {
    name: 'contracts',
    icon: ContractIcon,
    activeIcon: ActiveContractIcon,
    url: 'disabled',
  },
  {
    name: 'time_tracking',
    icon: TimeTrackingIcon,
    activeIcon: ActiveTimeTrackingIcon,
    url: 'disabled',
  },
  {
    name: 'fines',
    icon: FineIcon,
    activeIcon: ActiveFineIcon,
    url: 'disabled',
  },
  {
    name: 'conditions',
    icon: ConditionIcon,
    activeIcon: ActiveConditionIcon,
    url: 'disabled',
  },
  {
    name: 'gatekeeper',
    icon: GatekeeperIcon,
    activeIcon: ActiveGatekeeperIcon,
    url: '/gatekeeper',
  },
  {
    name: 'setup',
    icon: SetupIcon,
    activeIcon: ActiveSetupIcon,
    url: 'disabled',
  },
];

type PageIconType =
  | 'home'
  | 'search'
  | 'projects'
  | 'cancellations'
  | 'students'
  | 'verification'
  | 'contracts'
  | 'time_tracking'
  | 'fines'
  | 'conditions'
  | 'gatekeeper'
  | 'setup';

interface GenericPageProps<T> extends NavTabProps<T> {
  title?: string;
  backHref?: string;
  iconName?: PageIconType;
  children: React.ReactNode;
}

export default function PageLayout<T extends string>({
  title,
  iconName,
  backHref,
  children,
  ...navProps
}: GenericPageProps<T>) {
  const classes = useStyles();
  const navigate = useNavigate();
  const messenger = useMessenger();
  const { formatMessage } = useIntl();

  const handleMenuItemClick = useCallback((url: string, text?: string) => {
    url === 'disabled'
      ? messenger.info(<UnderConstructionMessage text={text} />)
      : navigate(url);
  }, []);

  const getTitleIcon = useCallback(() => {
    const currentItem = menuItems.find(({ name }) => name === iconName);
    return currentItem ? currentItem.activeIcon : null;
  }, [iconName]);

  const handleLogout = useCallback(() => {
    removeAccessToken();
    handleMenuItemClick('/login');
  }, []);

  const ActiveIcon = getTitleIcon();

  const arrowStylesSx = useRef((theme: DefaultTheme) => ({
    color: theme.palette.grey[400],
    mr: 0.5,
  })).current;

  return (
    <>
      <MuiAppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Grid container alignItems="center" spacing={2}>
            {backHref && (
              <Link to={backHref} className={classes.backLink}>
                <ArrowCircleLeftIcon sx={arrowStylesSx} fontSize="large" />
                <Typography variant="body1" color="textPrimary">
                  {formatMessage(messages.back)}
                </Typography>
              </Link>
            )}

            <Grid>{ActiveIcon != null && <ActiveIcon />}</Grid>

            {title && (
              <Grid>
                <Typography variant="h4" noWrap>
                  {title}
                </Typography>
              </Grid>
            )}

            <Grid>
              <NavTabs {...navProps} />
            </Grid>
          </Grid>
        </Toolbar>
      </MuiAppBar>

      <MuiDrawer variant="permanent" className={classes.drawer}>
        <Box pt={1.5} pb={1}>
          <UcmLogoIcon height={40} width={DRAWER_WIDTH} />
        </Box>

        <Stack flex={1} justifyContent="space-between">
          <List>
            {menuItems.map(
              ({ name, icon: Icon, activeIcon: ActiveIcon, url }) => (
                <ListItem key={name} disablePadding>
                  <Tooltip title={name} placement="right">
                    <ListItemButton
                      sx={{ px: 0 }}
                      onClick={() => handleMenuItemClick(url, name)}
                    >
                      <ListItemIcon className={classes.listItemIcon}>
                        {url !== 'disabled' &&
                        location.pathname.includes(url) ? (
                          <ActiveIcon />
                        ) : (
                          <Icon />
                        )}
                      </ListItemIcon>
                    </ListItemButton>
                  </Tooltip>
                </ListItem>
              ),
            )}
          </List>

          <List>
            <ListItem disablePadding>
              <Tooltip title="Logout" placement="right">
                <ListItemButton sx={{ px: 0 }} onClick={handleLogout}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <LogoutIcon />
                  </ListItemIcon>
                </ListItemButton>
              </Tooltip>
            </ListItem>
          </List>
        </Stack>
      </MuiDrawer>

      <Box className={classes.content}>{children}</Box>
    </>
  );
}
