import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { IconButton } from '@mui/material';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import { toMoment } from 'src/utils/datetime';
import messages from '../../messages';

interface Props {
  monthIndex: number;
  months: string[];
  data?: Record<string, UCM.CalendarJobType>;
  pressReject: (data: UCM.CalendarJobType) => void;
  pressApprove: (data: UCM.CalendarJobType) => void;
  pressDay: (day: string | null) => void;
}

export default function JobCalendarDays({
  monthIndex,
  months,
  data,
  pressReject,
  pressApprove,
  pressDay,
}: Props) {
  const [selected, setSelected] = useState<string | null>(null);
  const { formatMessage } = useIntl();

  const handlePressReject = useCallback(
    (e: React.MouseEvent<HTMLElement>, data: UCM.CalendarJobType) => {
      e.stopPropagation();
      pressReject(data);
    },
    [pressReject],
  );

  const handlePressApprove = useCallback(
    (e: React.MouseEvent<HTMLElement>, data: UCM.CalendarJobType) => {
      e.stopPropagation();
      pressApprove(data);
    },
    [pressApprove],
  );

  const handlePressDay = useCallback(
    (date: string) => {
      let day = selected !== date ? date : null;
      pressDay(day);
      setSelected(day);
    },
    [pressDay, selected],
  );

  const days = useMemo(() => {
    const month = months?.[monthIndex];
    if (!month) return null;

    const firstDay = toMoment(month)?.startOf('month').startOf('week');
    const lastDay = toMoment(month)?.endOf('month').endOf('week');
    const currMonth = toMoment(month)?.month();
    const currDay = firstDay;
    let currWeek = null;
    let dates = [] as JSX.Element[];

    const weeks = [];

    while (currDay && lastDay && currDay < lastDay) {
      if (currWeek !== currDay?.week()) {
        dates = [];
        currWeek = currDay?.week();
        weeks.push(
          <Grid
            key={currWeek}
            container
            size={12}
            sx={{
              '&:last-child > div': {
                borderBottom: 'none',
              },
            }}
          >
            {dates}
          </Grid>,
        );
      }

      const disabledDay = currDay.month() !== currMonth;
      const dayColor = disabledDay ? 'textSecondary' : 'secondary';
      const currDayString = currDay?.format(moment.HTML5_FMT.DATE);
      const currDayData = data?.[currDayString];

      const bgColor = () => {
        switch (currDayData?.status) {
          case 'accepted':
          case 'booked':
            return '#A8F2CF';
          case 'rejected':
          case 'unbooked':
            return '#FFDAD6';
          default:
            return '#fff';
        }
      };

      dates.push(
        <Grid
          key={currDayString}
          flex={1}
          height={'90px'}
          px={0.5}
          pb={1}
          onClick={() => handlePressDay(currDayString)}
          sx={{
            opacity: disabledDay ? 0.7 : 1,
            pointerEvents: disabledDay ? 'none' : 'pointer',
            borderBottom: '0.398px solid #E6E6E6',
            borderRight: '0.398px solid #E6E6E6',
            '&:last-child': {
              borderRight: 'none',
            },
            backgroundColor: currDayString === selected ? '#C2E8FF' : bgColor(),
          }}
        >
          <Typography fontSize={12} fontWeight={500} color={dayColor}>
            {currDay.format('DD')}
          </Typography>

          {currDayData?.isWaitlisted && (
            <Typography color="textPrimary" variant="caption">
              ({formatMessage(messages.waitlistedLabel)})
            </Typography>
          )}

          {currDayData && (
            <Box mt={0.5}>
              <JobBadge
                label="M"
                filled={currDayData?.bookedCount}
                count={currDayData?.slotsNumber}
                color="#836942"
                bgColor="#FEF0C8"
              />
            </Box>
          )}
          <Grid container spacing={0.5}>
            {currDayData &&
              (currDayData?.status === 'unbooked' ||
                currDayData?.status === 'cancelled' ||
                currDayData?.status === 'applied') && (
                <Grid>
                  <IconButton
                    size="small"
                    sx={{
                      width: '28px',
                      height: '8px',
                      borderRadius: '6px',
                      backgroundColor: '#72A98F',
                      '&:hover': {
                        backgroundColor: '#52886e',
                      },
                    }}
                    onClick={(e) => handlePressApprove(e, currDayData)}
                  >
                    <AddOutlinedIcon sx={{ color: '#fff', fontSize: '10px' }} />
                  </IconButton>
                </Grid>
              )}
            {currDayData &&
              (currDayData?.status === 'booked' ||
                currDayData?.status === 'applied') && (
                <Grid>
                  <IconButton
                    size="small"
                    sx={{
                      width: '28px',
                      height: '8px',
                      borderRadius: '6px',
                      backgroundColor: '#F64740',
                      '&:hover': {
                        backgroundColor: '#c2342e',
                      },
                    }}
                    onClick={(e) => handlePressReject(e, currDayData)}
                  >
                    <RemoveOutlinedIcon
                      sx={{ color: '#fff', fontSize: '10px' }}
                    />
                  </IconButton>
                </Grid>
              )}
          </Grid>
          {currDayData?.status === 'rejected' && (
            <Grid>
              <IconButton
                size="small"
                sx={{
                  borderRadius: '6px',
                  padding: '1px 6px',
                  background: '#F64740',
                }}
                onClick={(e) => handlePressApprove(e, currDayData)}
              >
                <Typography fontSize={8} color="#fff" lineHeight={'normal'}>
                  {formatMessage(messages.reapplyLabel)}
                </Typography>
              </IconButton>
            </Grid>
          )}
          {currDayData?.status === 'accepted' && (
            <Grid>
              <IconButton
                size="small"
                sx={{
                  borderRadius: '6px',
                  border: '1px solid  #001E2B',
                  padding: '1px 6px',
                }}
                onClick={(e) => handlePressReject(e, currDayData)}
              >
                <Typography fontSize={8} color="#000" lineHeight={'normal'}>
                  {formatMessage(messages.cancelLabel)}
                </Typography>
              </IconButton>
            </Grid>
          )}
        </Grid>,
      );
      currDay.add(1, 'day');
    }
    return weeks;
  }, [data, months, monthIndex, selected]);

  return <>{days}</>;
}

interface JobBadgeProps {
  label: string;
  filled: number;
  count: number;
  color?: string;
  bgColor?: string;
}

const JobBadge = ({ label, filled, count, color, bgColor }: JobBadgeProps) => {
  const remainingSlots = count - filled;

  return (
    <Stack
      flexDirection={'row'}
      alignItems={'center'}
      bgcolor={bgColor}
      borderRadius={'6px'}
      sx={{
        padding: '2px 4px',
        width: 'fit-content',
        marginBottom: '2px',
      }}
    >
      <Typography fontSize={12}>{label}</Typography>
      <Typography fontSize={12} color={color} fontWeight={600}>
        {filled}/{count}
      </Typography>

      {remainingSlots > 0 && (
        <Box
          borderRadius={'100%'}
          bgcolor={'#F64740'}
          width={'12px'}
          height={'12px'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          ml={0.5}
        >
          <Typography fontSize={8} color="#fff">
            {remainingSlots}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
