import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { API } from 'src/reactQuery/Api';
import { QUERY_KEYS } from 'src/reactQuery/QueryKeys';
import { useMessageHandler } from 'src/utils/hooks/useMessageHandler';
import { postRequest } from 'src/utils/request';
import messages from 'src/pages/ProjectDetailsPage/messages';

const cancellationNotices = async (data: {
  explanation?: string;
  reason_code?: string;
  user_uuid?: string;
  admin_comment?: string;
  job_shift_uuids?: string[];
}) => {
  const payload = { cancellation_notice: { ...data } };

  const res = await postRequest(API.POST.cancellationNotices, payload);
  return res.data;
};

export const useCreateCancellationNotices = () => {
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { handleError, handleSuccess } = useMessageHandler();

  return useMutation({
    mutationFn: cancellationNotices,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.JOB_APPLICATIONS_COUNT],
      });
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.JOB_APPLICATIONS],
      });
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.CANCELLATION_SHIFTS],
      });
      client.invalidateQueries({
        queryKey: [QUERY_KEYS.CALENDAR_JOBS],
      });
      handleSuccess(formatMessage(messages.applicationCancelled));
    },
    onError: handleError,
  });
};
