import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { SvgIconOwnProps } from '@mui/material';

import { toMoment } from 'src/utils/datetime';

interface Props {
  months: string[];
  monthIndex: number;
  onPreviousClick: () => void;
  onNextClick: () => void;
}

export default function JobCalendarMonth({
  months,
  monthIndex,
  onPreviousClick,
  onNextClick,
}: Props) {
  const month = months?.[monthIndex];
  if (!month) return null;

  return (
    <Grid container size={12} p={1.5} alignItems={'center'}>
      <StyledButton
        disabled={monthIndex === 0}
        onClick={onPreviousClick}
        Icon={ChevronLeftIcon}
      />
      <Typography fontSize={16} fontWeight={600} color="#1C1B1F">
        {toMoment(month)?.format('MMMM YYYY')}
      </Typography>
      <StyledButton
        disabled={monthIndex === months.length - 1}
        onClick={onNextClick}
        Icon={ChevronRightIcon}
      />
    </Grid>
  );
}

const StyledButton = ({
  disabled,
  Icon,
  onClick,
}: {
  onClick: () => void;
  disabled?: boolean;
  Icon: React.FC<SvgIconOwnProps>;
}) => {
  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      sx={{
        padding: '0 8px',
        '&:hover': {
          backgroundColor: 'inherit',
        },
      }}
    >
      <Icon sx={{ color: '#1C1B1F' }} />
    </IconButton>
  );
};
