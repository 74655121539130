import { useCallback, useEffect } from 'react';

import { useIntl } from 'react-intl';

import { useFormik } from 'formik';
import { date, object } from 'yup';
import moment, { type Moment } from 'moment';

import Grid from '@mui/material/Grid2';

import { forceString } from 'src/utils/datetime';
import { buildHelperText } from 'src/utils/components';
import { DatePickerField } from 'src/components/DatePickerField';

import globalMessages from 'src/messages';

interface DateRangeFieldProps {
  helperText?: string;
  field: UCM.DynamicFieldType;
  value: { startDate?: string; endDate?: string };
  onChange: (
    field: UCM.DynamicFieldType,
    {
      startDate,
      endDate,
    }: { startDate: string | null; endDate: string | null },
  ) => void;
}

export default function DateRangeField({
  value,
  field,
  onChange,
  helperText = '',
}: DateRangeFieldProps) {
  const { formatMessage } = useIntl();

  const formik = useFormik({
    validateOnChange: true,
    initialValues: {
      startDate: value?.startDate ? moment(value.startDate) : null,
      endDate: value?.endDate ? moment(value.endDate) : null,
    },
    validationSchema: object({
      startDate: date().nullable().notRequired(),
      endDate: date().nullable().notRequired(),
    }),
    validate: ({ startDate, endDate }) => {
      const errors: {
        startDate?: string;
        endDate?: string;
      } = {};

      if (!startDate || !endDate) return errors;

      if (startDate.isAfter(endDate))
        errors.startDate = formatMessage(globalMessages.maxDateField, {
          max: forceString(endDate),
        });
      if (endDate.isBefore(startDate))
        errors.endDate = formatMessage(globalMessages.minDateField, {
          min: forceString(startDate),
        });
      return errors;
    },
    onSubmit: () => {},
  });

  const handleStartDateChange = useCallback((date: Moment | null) => {
    formik.setFieldValue('startDate', date);
  }, []);

  const handleEndDateChange = useCallback((date: Moment | null) => {
    formik.setFieldValue('endDate', date);
  }, []);

  useEffect(() => {
    if (formik.dirty)
      onChange(field, {
        startDate: forceString(formik.values.startDate, moment.HTML5_FMT.DATE),
        endDate: forceString(formik.values.endDate, moment.HTML5_FMT.DATE),
      });
  }, [formik.values.startDate, formik.values.endDate, formik.validateForm]);

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12, md: 6 }}>
        <DatePickerField
          label={`${field.label} (min)`}
          value={formik.values.startDate}
          onChange={handleStartDateChange}
          helperText={
            formik.errors.startDate ||
            buildHelperText(field.description, helperText)
          }
          error={!!formik.errors.startDate}
        />
      </Grid>

      <Grid size={{ xs: 12, md: 6 }}>
        <DatePickerField
          label={`${field.label} (max)`}
          value={formik.values.endDate}
          onChange={handleEndDateChange}
          helperText={
            formik.errors.endDate ||
            buildHelperText(field.description, helperText)
          }
          error={!!formik.errors.endDate}
        />
      </Grid>
    </Grid>
  );
}
