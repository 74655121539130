import { useCallback, useMemo, useRef, useState } from 'react';

import moment from 'moment';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import { array, number, object, string } from 'yup';

import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import {
  toMoment,
  toTimezone,
  forceString,
  forceTimeString,
} from 'src/utils/datetime';
import { locale } from 'src/config';
import {
  useCreateBackupJob,
  useGetBackupShiftOptions,
} from 'src/pages/ProjectDetailsPage/api';
import globalMessages from 'src/messages';
import DataTable from 'src/components/DataTable';
import ConfirmModal from 'src/components/ConfirmModal';
import TimeInput from 'src/components/TimeInput';
import { formatNumber, toParsableNumber } from 'src/utils/standards';
import { useUpdateBackupJob } from 'src/pages/ProjectDetailsPage/api/mutations/useUpdateBackupJob';
import ShiftEditPanel, {
  type ShiftFormikType,
} from 'src/pages/ProjectDetailsPage/components/JobList/components/ShiftsForm/components/ShiftEditPanel';
import { useGetBackupJobDetails } from 'src/pages/ProjectDetailsPage/api/queries/useGetBackupJobDetails';

import messages from './messages';
import BackupJobEditPanel from './components/BackupJobEditPanel';

import type { Moment } from 'moment';
import type { SelectChangeEvent } from '@mui/material';

const DATA_TABLE_HEIGHT = 35;
const WORKED_HOURS_WARNING = 12.5;
const WORKED_MINUTES_LIMIT = WORKED_HOURS_WARNING * 60;

const useStyles = makeStyles((theme) => ({
  dataTable: { maxHeight: theme.spacing(DATA_TABLE_HEIGHT) },
}));

export interface FormikState {
  status: string;
  quantity: string;
  wageBonus: string;
  endsAt: Array<string>;
  startsAt: Array<string>;
  shiftIndexes: Array<number>;
  newEndsAt: Array<Moment | null>;
  newStartsAt: Array<Moment | null>;
}

interface BackupJobFormProps {
  isFlex: boolean;
  jobUuid?: string;
  backupUuid?: string;
}

export default function BackupJobForm({
  isFlex,
  jobUuid,
  backupUuid,
}: BackupJobFormProps) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [newBackupShifts, setNewBackupShifts] = useState<
    Array<UCM.BackupJobShiftOptionType>
  >([]);
  const [validateOnChange, setValidateOnChange] = useState(false);

  const { data: backupDetails } = useGetBackupJobDetails(backupUuid, {
    enabled: false,
  });

  const { data: shiftOptions } = useGetBackupShiftOptions(
    jobUuid ?? '',
    { enabled: (!!jobUuid || !!backupUuid) && open },
    backupUuid,
  );

  const { mutate: createBackupJob, isPending: isCreating } =
    useCreateBackupJob();

  const { mutate: updateBackupJob, isPending: isUpdating } =
    useUpdateBackupJob();

  const initialValues: FormikState = useMemo(
    () => ({
      status: backupDetails?.statusName ?? '',
      quantity: backupDetails?.quantity ?? '',
      wageBonus: formatNumber(backupDetails?.salaryBonus ?? 0, locale),
      endsAt: map(shiftOptions, 'endsAt'),
      startsAt: map(shiftOptions, 'startsAt'),
      newEndsAt: map(shiftOptions, ({ selected }) =>
        selected ? toMoment(selected.endsAt) : null,
      ),
      newStartsAt: map(shiftOptions, ({ selected }) =>
        selected ? toMoment(selected.startsAt) : null,
      ),
      shiftIndexes: map(shiftOptions, ({ selected }, index) =>
        selected ? index : null,
      ).filter((item): item is number => item !== null), // For some reason had to do this to stop TS complaining
    }),
    [shiftOptions, backupDetails],
  );

  const formik = useFormik<FormikState>({
    initialValues,
    validateOnChange,
    enableReinitialize: true,
    validate: ({ wageBonus }) => {
      const errors: { wageBonus?: string } = {};
      const min = 0;
      const bonus = parseFloat(toParsableNumber(wageBonus, locale));
      if (Number.isNaN(bonus))
        errors.wageBonus = formatMessage(globalMessages.notTypeField, {
          type: 'number',
        });
      if (bonus < min)
        errors.wageBonus = formatMessage(globalMessages.minValueField, {
          min: min,
        });

      return errors;
    },
    validationSchema: object().shape({
      status: string().required(),
      wageBonus: string().required(),
      quantity: number().required().integer().min(1),
      shiftIndexes: array()
        .of(number().integer())
        .min(1, formatMessage(messages.shiftIndexRequired))
        .required(),
    }),
    onSubmit: () => {},
  });

  const { errors, values } = formik;

  const handleShiftIndexesChange = useCallback(
    (shiftIndexes: Array<number>) =>
      formik.setFieldValue('shiftIndexes', shiftIndexes),
    [],
  );

  const handleCheckAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      if (!shiftOptions || isEmpty(shiftOptions)) return;

      if (shiftOptions.length === values.shiftIndexes.length) {
        handleShiftIndexesChange([]);
        return;
      }
      handleShiftIndexesChange(map(shiftOptions, (shift, index) => index));
    },
    [shiftOptions, values.shiftIndexes.length],
  );

  const handleCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
      event.stopPropagation();
      const updatedChecked = [...values.shiftIndexes];
      const foundIndex = findIndex(
        values.shiftIndexes,
        (shiftIndex: number) => shiftIndex === index,
      );

      if (foundIndex === -1) {
        updatedChecked.push(index);
      } else {
        updatedChecked.splice(foundIndex, 1);
      }
      handleShiftIndexesChange(updatedChecked);
    },
    [values.shiftIndexes.length],
  );

  const columns: Array<{
    id: keyof UCM.BackupJobShiftOptionType | string;
    label: string | React.ReactNode;
  }> = useMemo(
    () => [
      {
        id: 'multi-select',
        label: (
          <Checkbox
            checked={
              shiftOptions != null && values.shiftIndexes.length !== 0
                ? shiftOptions.length === values.shiftIndexes.length
                : false
            }
            onChange={handleCheckAll}
            disabled={shiftOptions == null || shiftOptions?.length === 0}
          />
        ),
        size: 'small',
        render: (
          _: Object = {},
          shift: UCM.BackupJobShiftOptionType,
          index: number,
        ) => {
          return (
            <Checkbox
              tabIndex={-1}
              onChange={(event) => handleCheck(event, index)}
              checked={values.shiftIndexes.includes(index)}
            />
          );
        },
      },
      {
        id: 'date',
        label: formatMessage(messages.dateColumnLabel),
        render: (_: Object = {}, shift: UCM.BackupJobShiftOptionType) => (
          <Typography variant="inherit">
            {forceString(shift.startsAt)}
          </Typography>
        ),
      },
      {
        id: 'originalTimeframe',
        label: formatMessage(messages.originalTimeColumnLabel),
        align: 'center',
        render: (_: Object = {}, shift: UCM.BackupJobShiftOptionType) => (
          <Typography variant="inherit">
            {[
              forceTimeString(shift.startsAt),
              forceTimeString(shift.endsAt),
            ].join(' - ')}
          </Typography>
        ),
      },
      {
        id: 'adjustedTimeframe',
        label: formatMessage(messages.adjustedTimeColumnLabel),
        align: 'center',
        render: (
          _: Object = {},
          shift: UCM.BackupJobShiftOptionType,
          index: number,
        ) => {
          return (
            <Grid container justifyContent="center">
              <Grid size={3}>
                <TimeInput
                  name={`newStartsAt.${index}`}
                  value={values.newStartsAt[index] || null}
                  onChange={handleStartTimeChange(shift, index)}
                />
              </Grid>

              <Typography>&nbsp;&nbsp;-&nbsp;&nbsp;</Typography>

              <Grid size={3}>
                <TimeInput
                  name={`newEndsAt.${index}`}
                  value={values.newEndsAt[index] || null}
                  onChange={handleEndTimeChange(shift, index)}
                />
              </Grid>
            </Grid>
          );
        },
      },
      {
        id: 'remaining',
        label: formatMessage(messages.remainingColumnLabel),
        size: 'small',
        align: 'center',
        render: (remainingSlots: number) => (
          <Typography>{remainingSlots}</Typography>
        ),
      },
    ],
    [shiftOptions, values.shiftIndexes.length, handleCheckAll, handleCheck],
  );

  const handleStartTimeChange = useRef(
    (record: UCM.BackupJobShiftOptionType, index: number) =>
      (newStartTime: Moment | null) => {
        if (!newStartTime) return;

        const startTime = toTimezone(record.startsAt);
        const endTime = toTimezone(record.endsAt);

        if (!startTime || !endTime) return;

        newStartTime = newStartTime
          .clone()
          .year(startTime.year())
          .month(startTime.month())
          .date(startTime.date());

        // Adjust if the new start time is incorrect after both start & end time
        if (newStartTime.isAfter(startTime) && newStartTime.isAfter(endTime)) {
          newStartTime = newStartTime.subtract(1, 'days');
        }

        let currEndTime = values.newEndsAt?.[index] || null;

        // Ensure currEndTime is not null
        if (currEndTime) {
          currEndTime = currEndTime.clone();

          while (newStartTime.isAfter(currEndTime)) {
            currEndTime.add(1, 'days');
          }

          while (
            currEndTime.diff(newStartTime, 'minutes') > WORKED_MINUTES_LIMIT
          ) {
            currEndTime.subtract(1, 'days');
          }

          if (newStartTime.isAfter(currEndTime)) {
            currEndTime.add(1, 'days');
          }
        }
        formik.setFieldValue(`newStartsAt.${index}`, newStartTime, false);
      },
  ).current;

  const handleEndTimeChange = useRef(
    (record: UCM.BackupJobShiftOptionType, index: number) =>
      (newEndTime: Moment | null) => {
        if (!newEndTime) return;

        const startTime = toTimezone(record.startsAt);
        const endTime = toTimezone(record.endsAt);

        if (!startTime || !endTime) return;

        // Clone newEndTime before modifying
        newEndTime = newEndTime
          .clone()
          .year(startTime.year())
          .month(startTime.month())
          .date(startTime.date());

        if (newEndTime.isBefore(startTime) && newEndTime.isBefore(endTime)) {
          newEndTime = newEndTime.add(1, 'days');
        }

        let currStartTime = values.newStartsAt?.[index] || null;

        if (currStartTime) {
          currStartTime = currStartTime.clone();

          while (newEndTime.isBefore(currStartTime)) {
            newEndTime.add(1, 'days');
          }

          while (
            newEndTime.diff(currStartTime, 'minutes') > WORKED_MINUTES_LIMIT
          ) {
            newEndTime.subtract(1, 'days');
          }

          if (newEndTime.isBefore(currStartTime)) {
            newEndTime.add(1, 'days');
          }
        }
        formik.setFieldValue(`newEndsAt.${index}`, newEndTime, false);
      },
  ).current;

  const buildShiftParam = useCallback((shift: ShiftFormikType) => {
    const endTime = toTimezone(shift.endTime);
    const startTime = toTimezone(shift.startTime);
    if (!shift.date || !shift.date.isValid()) {
      return { startTime, endTime };
    }
    if (startTime) {
      startTime
        .year(shift.date.year())
        .month(shift.date.month())
        .date(shift.date.date());
    }
    if (endTime) {
      endTime
        .year(shift.date.year())
        .month(shift.date.month())
        .date(shift.date.date());
    }
    if (startTime && endTime) {
      const diffInHours = moment.duration(endTime.diff(startTime)).as('hours');
      if (diffInHours < 0) {
        endTime.add(1, 'days');
      }
    }
    return { startTime, endTime };
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    formik.resetForm();
    setNewBackupShifts([]);
    setValidateOnChange(false);
  }, []);

  const onBackupShiftAdd = useCallback((shift: ShiftFormikType) => {
    const newshift = buildShiftParam(shift);

    setNewBackupShifts((prevShifts) => {
      const newShifts = mapToBackupShifts(newshift);
      return [newShifts, ...prevShifts];
    });
  }, []);

  const handleSubmit = useCallback(
    async (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setValidateOnChange(true);
      const errors = await formik.validateForm();
      const isValid = isEmpty(errors);

      if (!isValid) throw errors;

      const { endsAt, startsAt, shiftIndexes, newStartsAt, newEndsAt } = values;
      const shifts = map(shiftIndexes, (index) => ({
        startsAt: newStartsAt[index] || startsAt[index],
        endsAt: newEndsAt[index] || endsAt[index],
      }));

      const payload = {
        shifts,
        status: values.status,
        quantity: values.quantity,
        wageBonus: toParsableNumber(formik.values.wageBonus, locale),
      };

      return new Promise<boolean>((resolve) => {
        const mutationOptions = {
          onSuccess: () => {
            handleClose();
            resolve(true);
          },
          onError: () => resolve(false),
        };

        if (backupUuid) {
          return updateBackupJob(
            { ...payload, uuid: backupUuid },
            mutationOptions,
          );
        }
        if (jobUuid) {
          return createBackupJob(
            { ...payload, uuid: jobUuid },
            mutationOptions,
          );
        }
      });
    },
    [values, backupUuid],
  );

  const updatedRows = useMemo(
    () => [...newBackupShifts, ...(shiftOptions ?? [])],
    [newBackupShifts, shiftOptions],
  );

  const handleChange = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<unknown>,
    ) => {
      event.stopPropagation();
      formik.handleChange(event);
    },
    [formik],
  );

  return (
    <ConfirmModal
      width="900px"
      onClose={handleClose}
      onConfirm={handleSubmit}
      onOpen={() => setOpen(true)}
      disabled={isCreating || isUpdating}
      title={formatMessage(messages.title)}
      triggerButton={(handleOpen) => {
        return (
          <Button
            onClick={handleOpen}
            variant={!backupUuid ? 'outlined' : 'contained'}
            startIcon={!backupUuid && <AddOutlinedIcon color="primary" />}
          >
            {backupUuid
              ? formatMessage(messages.backupEditLabel)
              : formatMessage(messages.backupJobButtonLabel)}
          </Button>
        );
      }}
    >
      <Grid container spacing={2}>
        <Grid size={12}>
          <ShiftEditPanel isFlex={isFlex} onSave={onBackupShiftAdd} />
        </Grid>

        <Grid container size={12} spacing={0.5}>
          <Typography variant="h5">
            {formatMessage(messages.shiftTableHeading)}
          </Typography>

          <FormControl fullWidth error={!!errors.shiftIndexes}>
            <DataTable
              columns={columns}
              rows={updatedRows}
              enabledFooter={false}
              className={classes.dataTable}
            />
            <FormHelperText>{errors.shiftIndexes}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid size={12}>
          <BackupJobEditPanel
            values={values}
            errors={errors}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </ConfirmModal>
  );
}

function mapToBackupShifts(shift: {
  startTime: Moment | null;
  endTime: Moment | null;
}): UCM.BackupJobShiftOptionType {
  return {
    remaining: 0,
    selected: null,
    startsAt: shift.startTime,
    endsAt: shift.endTime,
  };
}
