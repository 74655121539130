import React, {
  useRef,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import { makeStyles } from '@mui/styles';

import ConfirmationDialog from 'src/components/ConfirmationDialog';

import JobCalendarMonth from './components/JobCalendarMonth/JobCalendarMonth';
import JobCalendarWeekdays from './components/JobCalendarWeekdays/JobCalendarWeekdays';
import JobCalendarDays from './components/JobCalendarDays/JobCalendarDays';
import RejectReasonModal from '../JobDetails/components/RejectReasonModal/RejectReasonModal';
import { REJECT_REASON } from '../../api/queries/useGetRejectReason';
import AcceptReasonModal from '../JobDetails/components/AcceptReasonModal/AcceptReasonModal';
import { ACCEPT_REASON } from '../../api/queries/useGetAcceptReason';

import messages from './messages';
import CancellationModal, {
  CancellationType,
} from '../JobDetails/components/CancellationModal/CancellationModal';
import { useGetCandidate } from 'src/pages/Candidates/api';
import {
  useCreateCancellationNotices,
  useGetCalendarJobs,
  useUpdateAcceptShift,
  useUpdateCancellationNotices,
  useUpdateReapplyShift,
  useUpdateRebookShift,
  useUpdateRejectShift,
  useUpdateWithdrawCancellationNotices,
} from '../../api';
import RebookReasonModal from '../JobDetails/components/RebookReasonModal';
import { toMoment, toString } from 'src/utils/datetime';
import { dateFormat } from 'src/utils/datetime';
interface Props {
  uuid: string;
  job: UCM.JobType | null;
  candidate?: UCM.ApplicationType | null;
  pressDay: (day: string | null) => void;
}

interface ReasonData {
  jobUuid: string;
  applicationUuid: string;
  jobShiftUuid: string;
}

export interface CancellationData {
  reason_code: string;
  admin_comment?: string;
  explanation: string;
  job_shift_uuids: string[];
  user_uuid: string;
  type?: CancellationType;
  cancellationUuid?: string;
}

interface Cancellation {
  id: number;
  uuid: string;
}

export default function JobCalendar({ uuid, job, candidate, pressDay }: Props) {
  const { formatMessage } = useIntl();

  const classes = useStyles();
  const [monthIndex, setMonthIndex] = useState(0);

  const confirmResetParams = useRef();

  const reasonDataRef = useRef<ReasonData | null>(null);

  const [openReject, setOpenReject] = useState(false);
  const [openRebook, setOpenRebook] = useState(false);
  const [rejectReason, setRejectReason] = useState<string | null>(null);

  const [openCancellation, setOpenCancellation] = useState(false);
  const [cancellationReason, setCancellationReason] = useState<string | null>(
    null,
  );
  const [editCancellation, setEditCancellation] = useState<Cancellation | null>(
    null,
  );

  const [openApprove, setOpenApprove] = useState(false);
  const [approveReason, setApproveReason] = useState<string | null>(null);

  const months = useMemo(() => {
    const startDate = job?.firstShiftAt;
    const endDate = job?.lastShiftAt;
    if (startDate && endDate) {
      const today = moment().month();
      const startAsMoment = toMoment(startDate, dateFormat);
      const endAsMoment = toMoment(endDate, dateFormat)?.add(1, 'month');

      let localMonths = [];
      while (startAsMoment?.month() !== endAsMoment?.month()) {
        localMonths.push(
          toString(startAsMoment?.startOf('months'), moment.HTML5_FMT.DATE),
        );
        if (today === startAsMoment?.month()) {
          setMonthIndex(localMonths.length - 1);
        }
        startAsMoment?.add(1, 'month');
      }
      return localMonths;
    }
    return [];
  }, [job?.firstShiftAt, job?.lastShiftAt]);

  useEffect(() => {
    const indexMonth = months?.findIndex(
      (month) => month === months?.[monthIndex],
    );
    setMonthIndex(indexMonth < 0 ? 0 : indexMonth);
  }, [months]);

  const params = useMemo(
    () => ({ month: months?.[monthIndex], user_id: candidate?.id }),
    [months, monthIndex, candidate?.id],
  );

  const { data: candidateProfile } = useGetCandidate({
    uuid: candidate?.uuid,
    enabled: Boolean(candidate?.uuid),
  });

  const { data } = useGetCalendarJobs({
    jobUuid: uuid,
    params,
    enabled: Boolean(uuid) && Boolean(months?.[monthIndex]),
  });

  const { mutate: rejectShift, isPending: isPendingReject } =
    useUpdateRejectShift();

  const { mutate: acceptShift, isPending: isPendingApprove } =
    useUpdateAcceptShift();

  const { mutate: reApplyShift } = useUpdateReapplyShift();

  const { mutate: cancellationNotices } = useCreateCancellationNotices();

  const { mutate: updateCancellationNotices } = useUpdateCancellationNotices();

  const { mutate: updateRebookShift } = useUpdateRebookShift();

  const { mutate: withdrawCancellationNotices } =
    useUpdateWithdrawCancellationNotices();

  const handlePreviousMonthClick = useCallback(() => {
    setMonthIndex((prevMonthIndex) => prevMonthIndex - 1);
  }, []);

  const handleNextMonthClick = useCallback(() => {
    setMonthIndex((prevMonthIndex) => prevMonthIndex + 1);
  }, []);

  const pressReject = useCallback(
    (application: UCM.CalendarJobType) => {
      const { status, jobShiftUuid } = application;

      let reason: string = '';
      if (status === 'applied') {
        reason = REJECT_REASON.REJECT_APPLICATION;
      } else if (status === 'accepted') {
        reason = REJECT_REASON.CANCEL_APPLICATION;
      }

      if (job) {
        reasonDataRef.current = {
          jobUuid: uuid,
          applicationUuid: application.uuid,
          jobShiftUuid,
        };
      }

      if (status.match(/applied|accepted/)) {
        setRejectReason(reason);
        setOpenReject(true);
      }

      if (status === 'booked') {
        reason = REJECT_REASON.CANCELLATION_NOTICE;
        setCancellationReason(reason);
        setOpenCancellation(true);
      }
    },
    [job],
  );

  const pressApprove = useCallback(
    (application: UCM.CalendarJobType) => {
      const { status, uuid, jobShiftUuid } = application;
      if (job) {
        reasonDataRef.current = {
          jobUuid: uuid,
          applicationUuid: uuid,
          jobShiftUuid,
        };
      }
      if (status === 'applied') {
        setApproveReason(ACCEPT_REASON.ACCEPTANCE_APPLICATION);
        setOpenApprove(true);
      } else if (status === 'rejected' || status === 'cancelled') {
        // @ts-ignore
        confirmResetParams.current.handleDialogOpen();
      } else if (status === 'unbooked') {
        if (application?.cancellationNotice?.uuid) {
          setCancellationReason(REJECT_REASON.RESET_TIME_TRACKING);
          setEditCancellation(application?.cancellationNotice);
          setOpenCancellation(true);
        } else {
          setOpenRebook(true);
        }
      }
    },
    [job],
  );

  const cancelType = useMemo(() => {
    return rejectReason === REJECT_REASON.REJECT_APPLICATION
      ? 'reject'
      : 'cancel';
  }, [rejectReason]);

  const onClearReason = useCallback(() => {
    reasonDataRef.current = null;
  }, []);

  const handleReject = useCallback(
    (reason: string) => {
      if (reasonDataRef.current) {
        rejectShift(
          { ...reasonDataRef.current, reason, type: cancelType },
          {
            onSettled: onClearReason,
          },
        );
      }
    },
    [cancelType],
  );

  const handleAccept = useCallback((reason: string) => {
    if (reasonDataRef.current) {
      acceptShift(
        { ...reasonDataRef.current, reason },
        {
          onSettled: onClearReason,
        },
      );
    }
  }, []);

  const handleConfirmReset = useCallback(() => {
    if (reasonDataRef.current) {
      reApplyShift(
        { ...reasonDataRef.current },
        {
          onSettled: onClearReason,
        },
      );
    }
  }, []);

  const handleRebook = useCallback((reason: string) => {
    if (reasonDataRef.current) {
      updateRebookShift(
        {
          applicationUuid: reasonDataRef.current.jobShiftUuid,
          reasonCode: reason,
        },
        { onSettled: onClearReason },
      );
    }
  }, []);

  const handleCancellation = useCallback(
    (data: CancellationData) => {
      const { type, cancellationUuid, ...payload } = data;

      const filteredData = Object.fromEntries(
        Object.entries(payload).filter(
          ([_, value]) => value != null && value !== '',
        ),
      );

      if (type === 'save') {
        void cancellationNotices(filteredData);
      } else if (type === 'update') {
        void updateCancellationNotices({
          cancellationUuid,
          data: filteredData,
        });
      } else if (type === 'withdraw') {
        void withdrawCancellationNotices({
          cancellationUuid,
          reason_code: payload.reason_code,
        });
      }
    },
    [candidateProfile],
  );

  const holdOpenDialogFunction = useCallback(
    // @ts-ignore
    (confirmDialogParams) => (handleDialogOpen) => {
      confirmDialogParams.current = {
        ...confirmDialogParams.current,
        handleDialogOpen,
      };
      return <React.Fragment />;
    },
    [],
  );

  const closeReject = useCallback(() => {
    setOpenReject(false);
    setRejectReason(null);
    reasonDataRef.current = null;
  }, []);

  const closeApprove = useCallback(() => {
    setOpenApprove(false);
    setApproveReason(null);
    reasonDataRef.current = null;
  }, []);

  const closeRebook = useCallback(() => {
    setOpenRebook(false);
    reasonDataRef.current = null;
  }, []);

  const closeCancellation = useCallback(() => {
    setOpenCancellation(false);
    setCancellationReason(null);
    setEditCancellation(null);
    reasonDataRef.current = null;
  }, []);

  return (
    <>
      <Paper className={classes.calendar}>
        <Grid container>
          <JobCalendarMonth
            monthIndex={monthIndex}
            months={months}
            onPreviousClick={handlePreviousMonthClick}
            onNextClick={handleNextMonthClick}
          />
          <JobCalendarWeekdays monthIndex={monthIndex} months={months} />
          <JobCalendarDays
            months={months}
            monthIndex={monthIndex}
            data={data}
            pressReject={pressReject}
            pressApprove={pressApprove}
            pressDay={pressDay}
          />
        </Grid>
      </Paper>
      <RejectReasonModal
        type={rejectReason!}
        modalLabel={
          cancelType === 'reject'
            ? formatMessage(messages.rejectModalLabel)
            : formatMessage(messages.cancelApplicantConfirmTitle)
        }
        onConfirm={handleReject}
        open={openReject}
        onClose={closeReject}
        enabledFetch={openReject && Boolean(rejectReason)}
        isLoading={isPendingReject}
        disabledButton={isPendingReject}
      />
      <AcceptReasonModal
        type={approveReason!}
        modalLabel={formatMessage(messages.acceptModalLabel)}
        onConfirm={handleAccept}
        enabledFetch={openApprove && Boolean(approveReason)}
        open={openApprove}
        onClose={closeApprove}
        isLoading={isPendingApprove}
        disabledButton={isPendingApprove}
      />
      <ConfirmationDialog
        message={formatMessage(messages.resetApplicationConfirmMessage)}
        onConfirm={handleConfirmReset}
      >
        {holdOpenDialogFunction(confirmResetParams)}
      </ConfirmationDialog>
      <RebookReasonModal
        modalLabel={formatMessage(messages.rebookApplicationConfirmMessage)}
        onConfirm={handleRebook}
        enabledFetch={openRebook}
        open={openRebook}
        onClose={closeRebook}
        isLoading={isPendingApprove}
        disabledButton={isPendingApprove}
      />
      <CancellationModal
        type={cancellationReason!}
        enabledFetch={openCancellation && Boolean(cancellationReason)}
        onConfirm={handleCancellation}
        open={openCancellation}
        onClose={closeCancellation}
        candidate={candidateProfile}
        job={job}
        cancellation={editCancellation}
      />
    </>
  );
}

const useStyles = makeStyles(() => ({
  calendar: { maxWidth: '500px', flexGrow: 1, border: '1px solid #E2E2E2' },
}));
