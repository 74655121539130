import { MutableRefObject, useCallback, useMemo, useRef } from 'react';

import { useIntl } from 'react-intl';

import map from 'lodash/map';
import flow from 'lodash/flow';
import keyBy from 'lodash/keyBy';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import hashValues from 'lodash/values';

import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { locale } from 'src/config';
import Dropdown from 'src/components/Dropdown';
import TextInput from 'src/components/TextInput';
import { PencilAdornment } from 'src/utils/components';
import { checkCurrencyTyping } from 'src/utils/standards';
import ExpansionPanel from 'src/components/ExpansionPanel';
import { DatePickerField } from 'src/components/DatePickerField';
import AutoCompleteField, {
  type OptionType,
} from 'src/components/AutoCompleteField';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import StandbyChip from 'src/pages/ProjectDetailsPage/components/StandbyChip';
import FlexDateChip from 'src/pages/ProjectDetailsPage/components/FlexDateChip';
import resolveDynamicFieldRenderer from 'src/pages/ProjectDetailsPage/components/JobForm/components/FieldComponents';

import type {
  SelectChangeEvent,
  AutocompleteChangeReason,
} from '@mui/material';
import type { Moment } from 'moment';
import type {
  JobParamsType,
  DynamicOptionType,
} from 'src/pages/ProjectDetailsPage/api';
import type { FieldValueType } from '../..';
import type { FormikErrors } from 'formik/dist';

import messages from './messages';

const checkParam = { locale };

export type FieldValueChangeType = {
  value: Record<string, boolean>;
};

interface JobFormEditPanelProps {
  isFlex: boolean;
  isStandby: boolean;
  values: JobParamsType;
  errors: FormikErrors<JobParamsType>;
  staffTypeInputRef: MutableRefObject<string>;
  tariffOptions: Array<{
    label: string;
    tariff: number;
    value: string;
  }>;
  dynamicFieldOptions: Array<DynamicOptionType>;
  jobFieldsToShow: Record<string, Array<UCM.DynamicFieldType>>;
  cityOptions: Array<UCM.CityOptionType>;
  taskOptions: Array<UCM.TaskOptionType>;
  jobTypeOptions: Array<UCM.JobTypeOptionType>;
  languageOptions: Array<UCM.LanguageOptionType>;
  staffTypeOptions: Array<UCM.StaffTypeOptionType>;
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<unknown>,
  ) => void;
  onValueChange: (
    name: string,
    value: string | number | Moment | null | Array<string>,
  ) => void;
  onFieldSelect: (fieldUuid: string, reason: AutocompleteChangeReason) => void;
  onStaffTypeSave: () => void;
  onStaffTypeDelete: () => void;
  onFieldChange: (
    field: UCM.DynamicFieldType,
    value: Record<string, string | Array<string> | null>,
  ) => void;
  jobFieldValues: FieldValueType;
}

export default function JobEditPanel({
  values,
  errors,
  isFlex,
  isStandby,
  cityOptions,
  taskOptions,
  tariffOptions,
  jobFieldValues,
  jobTypeOptions,
  jobFieldsToShow,
  languageOptions,
  staffTypeOptions,
  staffTypeInputRef,
  dynamicFieldOptions,
  onChange,
  onFieldChange,
  onValueChange,
  onFieldSelect,
  onStaffTypeSave,
  onStaffTypeDelete,
}: JobFormEditPanelProps) {
  const { formatMessage } = useIntl();

  const booleanOptions = useRef([
    { value: true, label: formatMessage(messages.yesLabel) },
    { value: false, label: formatMessage(messages.noLabel) },
  ]).current;

  const selectedLanguageOptions = useMemo(() => {
    return (
      languageOptions?.filter((option) =>
        values.languageCodes.includes(option.value),
      ) || []
    );
  }, [languageOptions, values.languageCodes]);

  const selectedTaskOptions = useMemo(() => {
    return (
      taskOptions?.filter((option) =>
        values.taskCodes.includes(option.value),
      ) || []
    );
  }, [taskOptions, values.taskCodes]);

  const selectedFieldUuids: Record<string, UCM.DynamicFieldType> = useMemo(
    () =>
      flow([hashValues, flatten, (fields) => keyBy(fields, 'uuid')])(
        jobFieldsToShow,
      ),
    [jobFieldsToShow],
  );
  const hashDynamicFieldOptions = useMemo(
    () => keyBy(dynamicFieldOptions, 'value'),
    [dynamicFieldOptions],
  );

  const selectedFieldOptions = useMemo(() => {
    return map(selectedFieldUuids, ({ uuid }) => hashDynamicFieldOptions[uuid]);
  }, [selectedFieldUuids, hashDynamicFieldOptions]);

  const handleCurrencyChange = useCallback(
    (
      event:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<unknown>,
    ) => {
      const {
        target: { value },
      } = event;

      if (checkCurrencyTyping(value as string, checkParam)) {
        onChange(event);
      }
    },
    [onChange],
  );

  const handleMultiFieldSelect = useCallback(
    (value: Array<OptionType> | null | OptionType, name: string) => {
      if (isArray(value)) {
        const values = [...value.map((option) => option.value)];
        onValueChange(name, values);
      }
    },
    [onValueChange],
  );

  const handleStaffTypeChange = useCallback(
    (option: { value: string; label: string } | null) => {
      if (!option) {
        onValueChange('staffTypeUuid', '');
        staffTypeInputRef.current = '';
        return;
      }

      onValueChange('staffTypeUuid', option.value);
      staffTypeInputRef.current = option.label;
    },
    [onValueChange],
  );

  const handleStaffTypeTextChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      staffTypeInputRef.current = e.target.value;
    },
    [],
  );

  const handleFieldSelect = useCallback(
    (
      values: Array<DynamicOptionType> | DynamicOptionType | null,
      reason: AutocompleteChangeReason,
    ) => {
      if (!values || !isArray(values)) {
        return;
      }

      // Extract the UUIDs from the previously selected fields
      const selectedFieldUuidsArray = Object.keys(selectedFieldUuids);

      // Get the UUIDs from the values that are currently selected
      const selectedValuesUuids = values.map((value) => value.value);

      // Identify UUIDs that are present in selectedFieldUuids but not in values
      const uuidsToDelete = selectedFieldUuidsArray.filter(
        (uuid) => !selectedValuesUuids.includes(uuid),
      );

      // Map through the uuidsToDelete and call onFieldSelect for each
      if (uuidsToDelete.length > 0) {
        return uuidsToDelete.forEach((uuid) => onFieldSelect(uuid, reason));
      }

      // Map through the selected values and call onFieldSelect for each
      return values.map(({ value }) => {
        return onFieldSelect(value, reason);
      });
    },
    [onFieldSelect, selectedFieldUuids],
  );

  return (
    <Grid container spacing={4}>
      {/* Job Details Section */}
      <Grid container spacing={2}>
        <Grid size={12}>
          <Typography fontWeight="600" fontSize={20}>
            {formatMessage(messages.detailSectionTitle)}
          </Typography>
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="title"
            value={values.title}
            error={!!errors.title}
            helperText={errors.title}
            label={formatMessage(messages.titleLabel)}
            onChange={onChange}
            EndIcon={() =>
              isStandby ? <StandbyChip /> : isFlex ? <FlexDateChip /> : null
            }
          />
        </Grid>

        <Grid container size={{ xs: 12, md: 6 }}>
          <Grid size={{ xs: 10, md: 8.5 }}>
            <AutoCompleteField
              freeSolo
              options={staffTypeOptions}
              value={values.staffTypeUuid}
              error={!!errors.staffTypeUuid}
              helperText={errors.staffTypeUuid}
              label={formatMessage(messages.staffTypeLabel)}
              onChange={(value) =>
                !isArray(value) && handleStaffTypeChange(value)
              }
              onTextChange={handleStaffTypeTextChange}
            />
          </Grid>

          <Grid size={{ xs: 1, md: 1.5 }}>
            <IconButton onClick={onStaffTypeSave}>
              <SaveOutlinedIcon color="primary" />
            </IconButton>
          </Grid>

          <Grid size={{ xs: 1, md: 2 }}>
            <ConfirmationDialog
              maxWidth="sm"
              onConfirm={onStaffTypeDelete}
              message={formatMessage(messages.deleteStaffTypeConfirmMessage)}
            >
              {(handleOpen) => (
                <IconButton
                  disabled={!values.staffTypeUuid}
                  onClick={handleOpen}
                >
                  <DeleteOutlineOutlinedIcon color="primary" />
                </IconButton>
              )}
            </ConfirmationDialog>
          </Grid>
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <Dropdown
            name="jobTypeCode"
            options={jobTypeOptions}
            value={values.jobTypeCode}
            error={!!errors.jobTypeCode}
            helperText={errors.jobTypeCode}
            placeholder={formatMessage(messages.jobTypeLabel)}
            onChange={onChange}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <Dropdown
            name="ignoreDesiredCities"
            value={values.ignoreDesiredCities}
            error={!!errors.ignoreDesiredCities}
            helperText={errors.ignoreDesiredCities}
            options={booleanOptions}
            placeholder={formatMessage(messages.ignoreDesiredCitiesLabel)}
            onChange={onChange}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 'grow' }}>
          <DatePickerField
            name="bookingDeadlineDate"
            value={values.bookingDeadlineDate}
            error={!!errors.bookingDeadlineDate}
            helperText={errors.bookingDeadlineDate}
            label={formatMessage(messages.bookingDeadlineDateLabel)}
            onChange={(e) => onValueChange('bookingDeadlineDate', e)}
          />
        </Grid>

        {!isFlex && (
          <Grid size={{ xs: 12, md: 6 }}>
            <TextInput
              fullWidth
              name="quantity"
              value={values.quantity}
              error={!!errors.quantity}
              helperText={errors.quantity}
              label={formatMessage(messages.quantityLabel)}
              onChange={onChange}
              EndIcon={PencilAdornment}
            />
          </Grid>
        )}

        <Grid size={12}>
          <AutoCompleteField
            multiple
            options={languageOptions}
            error={!!errors.languageCodes}
            value={selectedLanguageOptions}
            helperText={errors.languageCodes as string}
            label={formatMessage(messages.languagesLabel)}
            onChange={(val) => handleMultiFieldSelect(val, 'languageCodes')}
          />
        </Grid>

        <Grid size={12}>
          <AutoCompleteField
            multiple
            options={taskOptions}
            error={!!errors.taskCodes}
            value={selectedTaskOptions}
            helperText={errors.taskCodes as string}
            label={formatMessage(messages.tasksLabel)}
            onChange={(val) => handleMultiFieldSelect(val, 'taskCodes')}
          />
        </Grid>
        <Grid size={12}>
          <TextInput
            fullWidth
            multiline
            name="description"
            value={values.description}
            error={!!errors.description}
            helperText={errors.description}
            label={formatMessage(messages.descriptionLabel)}
            onChange={onChange}
          />
        </Grid>

        <Grid size={12}>
          <AutoCompleteField
            multiple
            options={dynamicFieldOptions}
            value={selectedFieldOptions ?? []}
            groupBy={(option) => option.dynamicField.category.name}
            label={formatMessage(messages.additionalAttributesLabel)}
            onChange={handleFieldSelect}
          />
        </Grid>

        {!isEmpty(jobFieldsToShow)
          ? Object.entries(jobFieldsToShow).map(([categoryName, fields]) => (
              <Grid size={12} key={categoryName} px={0.25}>
                <ExpansionPanel header={categoryName}>
                  {fields.map((field) => {
                    const AnswerField = resolveDynamicFieldRenderer(field);

                    return (
                      <Grid key={field.uuid} size={12} mt={4} mb={4}>
                        <AnswerField
                          field={field}
                          value={jobFieldValues?.[field.uuid] ?? ''}
                          helperText={
                            !field.isVisibleByUser
                              ? formatMessage(messages.notVisibleByUserLabel)
                              : undefined
                          }
                          onChange={onFieldChange}
                        />
                      </Grid>
                    );
                  })}
                </ExpansionPanel>
              </Grid>
            ))
          : null}
      </Grid>

      {/* Salary Section */}
      <Grid container spacing={2}>
        <Grid size={12}>
          <Typography fontWeight="600" fontSize={20}>
            {formatMessage(messages.salarySectionTitle)}
          </Typography>
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <Dropdown
            name="tariffCode"
            options={tariffOptions}
            value={values.tariffCode}
            error={!!errors.tariffCode}
            helperText={errors.tariffCode}
            placeholder={formatMessage(messages.tariffCodeLabel)}
            onChange={onChange}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="salary"
            error={!!errors.salary}
            value={values.salary}
            helperText={errors.salary}
            label={formatMessage(messages.salaryLabel)}
            onChange={handleCurrencyChange}
            EndIcon={PencilAdornment}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="salaryBonus"
            value={values.salaryBonus}
            error={!!errors.salaryBonus}
            helperText={errors.salaryBonus}
            label={formatMessage(messages.salaryBonusLabel)}
            onChange={handleCurrencyChange}
            EndIcon={PencilAdornment}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="price"
            value={values.price}
            error={!!errors.price}
            helperText={errors.price}
            label={formatMessage(messages.priceLabel)}
            onChange={handleCurrencyChange}
            EndIcon={PencilAdornment}
          />
        </Grid>

        {/* <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="priceBonus"
            value={values.priceBonus}
            error={!!errors.priceBonus}
            helperText={errors.priceBonus}
            label={formatMessage(messages.priceBonusLabel)}
            onChange={handleCurrencyChange}
            EndIcon={PencilAdornment}
          />
        </Grid> */}
      </Grid>

      {/* Work Address Section */}
      <Grid container spacing={2}>
        <Grid size={12}>
          <Typography fontWeight="600" fontSize={20}>
            {formatMessage(messages.workAddressSectionTitle)}
          </Typography>
        </Grid>

        <Grid size={12}>
          <TextInput
            fullWidth
            name="venue"
            value={values.venue}
            error={!!errors.venue}
            helperText={errors.venue}
            label={formatMessage(messages.venueLabel)}
            onChange={onChange}
            EndIcon={PencilAdornment}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="address"
            value={values.address}
            error={!!errors.address}
            helperText={errors.address}
            label={formatMessage(messages.addressLabel)}
            onChange={onChange}
            EndIcon={PencilAdornment}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="houseNumber"
            value={values.houseNumber}
            error={!!errors.houseNumber}
            helperText={errors.houseNumber}
            label={formatMessage(messages.houseNumberLabel)}
            onChange={onChange}
            EndIcon={PencilAdornment}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <Dropdown
            name="cityCode"
            options={cityOptions}
            value={values.cityCode}
            error={!!errors.cityCode}
            helperText={errors.cityCode}
            placeholder={formatMessage(messages.cityLabel)}
            onChange={onChange}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <TextInput
            fullWidth
            name="zipCode"
            value={values.zipCode}
            error={!!errors.zipCode}
            helperText={errors.zipCode}
            label={formatMessage(messages.zipCodeLabel)}
            onChange={onChange}
            EndIcon={PencilAdornment}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
