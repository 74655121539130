import { useMemo } from 'react';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import { toMoment } from 'src/utils/datetime';

interface Props {
  monthIndex: number;
  months: string[];
}
export default function JobCalendarWeekdays({ monthIndex, months }: Props) {
  const weekdaysContent = useMemo(() => {
    const month = months?.[monthIndex];
    if (!month) return null;

    const firstDay = toMoment(month)?.startOf('month').startOf('week');
    const firstWeek = firstDay?.week();
    const currDay = firstDay;
    const weekdays = [] as string[];
    while (firstWeek === currDay?.week()) {
      weekdays.push(currDay?.format('ddd') as string);
      currDay?.add(1, 'day');
    }
    return weekdays;
  }, [months, monthIndex]);

  if (!weekdaysContent) return null;

  return (
    <Grid container size={12}>
      {weekdaysContent.map((item: string) => (
        <Grid
          key={item}
          flex={1}
          justifyContent={'center'}
          alignContent={'center'}
          height={'44px'}
          sx={{
            backgroundColor: '#F6F6F6',
          }}
        >
          <Typography
            textTransform={'uppercase'}
            textAlign={'center'}
            fontSize={9}
          >
            {item}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}
