import timezone from 'moment-timezone';
import moment, { Moment } from 'moment';

const dateTimeSystemFormat = moment.HTML5_FMT.DATETIME_LOCAL_MS;
const timeFormat = moment.localeData().longDateFormat('LT');
const dateFormat = moment.localeData().longDateFormat('L');
const dateTimeFormat = `${dateFormat}, ${timeFormat}`;
const weekDateFormat = `ddd ${dateFormat}`;
const weekDayMonthFormat = 'ddd DD.MM';
const simpleWeekDateFormat = 'dd DD';
const monthYearFormat = 'MM.YYYY';
const dayMonthFormat = 'DD.MM';
const shortWeekFormat = 'ddd';
const weekFormat = 'dddd';

export function toTimezone(
  date?: Moment | Date | string | null,
  format?: string,
  strict: boolean = true,
): Moment | null {
  if (moment.isMoment(date) && date?.tz()) return date;
  if (moment.isMoment(date)) return timezone(date.format(dateTimeSystemFormat));
  return date ? timezone(date, format, strict) : null;
}

export function toMoment(
  date?: Moment | Date | string | null,
  format?: string,
  strict: boolean = true,
) {
  if (moment.isMoment(date)) return date;
  return date ? moment(date, format, strict) : null;
}

export function toDate(date?: Moment | Date | string | null, format?: string) {
  const momentDate = toMoment(date, format);
  return momentDate ? momentDate.toDate() : null;
}

export function toString(
  date?: Moment | Date | null,
  format: string = dateFormat,
) {
  if (moment.isMoment(date)) return date.format(format);
  return date instanceof Date ? timezone(date).format(format) : '';
}

export function forceString(
  date?: Moment | Date | string | null,
  format?: string,
) {
  return toString(toTimezone(date), format);
}

export function forceDateString(date?: Moment | Date | string | null) {
  return toDateString(toTimezone(date));
}

export function forceTimeString(date?: Moment | Date | string | null) {
  return toTimeString(toTimezone(date));
}

export function forceDateTimeString(date?: Moment | Date | string | null) {
  return toDateTimeString(toTimezone(date));
}

export function toDateString(date?: Moment | Date | null) {
  return toString(date);
}

export function toTimeString(date?: Moment | Date | null) {
  return toString(date, timeFormat);
}

export function toDateTimeString(date?: Moment | Date | null) {
  return toString(date, dateTimeFormat);
}

export function daysUntilToday(
  timestamp: Moment | Date | string | null,
  showOnlyDays?: boolean,
): string | null {
  if (timestamp == null) return null;

  const daysDiff = moment()
    .startOf('day')
    .diff(moment(timestamp).startOf('day'), 'days');

  if (daysDiff === 0) return 'Today';

  if (daysDiff === 1) return 'Yesterday';

  return showOnlyDays ? `${daysDiff} days` : `${daysDiff} days ago`;
}

export const isOlderThanThreeDays = (daysLabel: string): boolean => {
  if (daysLabel === 'Today' || daysLabel === 'Yesterday') {
    return false; // Today or Yesterday are not older than 3 days
  }

  // Extract the number of days from the label, e.g., "3 days ago"
  const match = daysLabel.match(/(\d+)\s+days?\s+ago/);
  if (match && match[1]) {
    const days = parseInt(match[1], 10);
    return days > 2; // Older than 3 days
  }

  return false; // Fallback for unexpected strings
};

export {
  timeFormat,
  dateFormat,
  dateTimeFormat,
  weekDateFormat,
  shortWeekFormat,
  weekDayMonthFormat,
  simpleWeekDateFormat,
  monthYearFormat,
  dayMonthFormat,
  weekFormat,
};
