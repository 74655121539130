import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import mapValues from 'lodash/mapValues';

// import Menu from '@mui/material/Menu';
import Grid from '@mui/material/Grid2';
// import { makeStyles } from '@mui/styles';
// import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { colors } from 'src/utils/customColors';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// const useStyles = makeStyles((theme) => ({
//   searchBar: {
//     flexGrow: 1,
//     '& fieldset': {
//       border: 'none',
//     },
//   },
//   filterButton: {
//     display: 'flex',
//     alignItems: 'center',
//     alignSelf: 'center',
//     width: theme.spacing(5),
//     height: theme.spacing(4),
//     justifyContent: 'center',
//     padding: theme.spacing(0, 1),
//     marginLeft: theme.spacing(1),
//     borderRadius: theme.spacing(1),
//     border: `thin solid ${colors.grey}`,
//   },
// }));

type FieldType = {
  id: string;
  type: 'date' | 'select' | 'text';
};

export type FilterStateType = Record<string, string | null | Array<unknown>>;

const initialState = (fields: Array<FieldType>): FilterStateType =>
  reduce(
    fields,
    (init: FilterStateType, field: FieldType) => {
      const accum = { ...init }; // Spread to ensure immutability
      if (field.type === 'date') {
        accum[field.id] = null;
      } else if (field.type === 'select') {
        accum[field.id] = [];
      } else {
        accum[field.id] = '';
      }
      return accum;
    },
    {},
  );

const trim = (object: FilterStateType) =>
  mapValues(object, (value) => {
    if (typeof value === 'string') return value.trim();
    return value;
  });

export function resolveQuickSearch(objectValues: Record<string, string>) {
  return filter(
    map(objectValues, (value, name) => ({ name, value })),
    ({ name, value }) => !isEmpty(name) && !isEmpty(value),
  );
}

export function resolveDataFilter(
  arrayValues: Array<
    Record<'name' | 'operator' | 'value', string | Array<string>>
  >,
) {
  return filter(
    arrayValues,
    ({ name, operator, value }) =>
      !isEmpty(name) && !isEmpty(operator) && !isEmpty(value),
  );
}

export interface QuickFiltersType extends FieldType {
  autoFocus?: boolean;
  placeholder: string;
}

type SearchVariant = 'noBorder' | 'default';

type SearchFilterBarProps = {
  // showFilter?: boolean;
  // filterOptions?: string[];
  fields: Array<QuickFiltersType>;
  onSearch?: (filters: FilterStateType) => void;
  variant?: SearchVariant;
  // onFilterSelect?: (selectedFilter: string) => void;
};

export default function SearchFilterBar({
  fields,
  onSearch,
  variant,
  // onFilterSelect,
  // showFilter = true,
  // filterOptions = ['Test 1', 'Test 2', 'Test 3'],
}: SearchFilterBarProps) {
  const [state, setState] = useState(initialState(fields));
  const [clearText, setClearText] = useState(false);
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // const classes = useStyles();

  // const open = Boolean(anchorEl);

  // const handleFilterClose = useCallback(() => setAnchorEl(null), []);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      setClearText(false);

      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [],
  );

  // const handleFilterClick = useCallback(
  //   (event: React.MouseEvent<HTMLButtonElement>) => {
  //     setAnchorEl(event.currentTarget);
  //   },
  //   [],
  // );

  // const handleFilterSelect = useCallback(
  //   (option: string) => {
  //     if (onFilterSelect) {
  //       onFilterSelect(option);
  //     }
  //     setAnchorEl(null);
  //   },
  //   [onFilterSelect],
  // );

  useEffect(() => {
    if (!clearText) handleSearch();
  }, [clearText]);

  const handleSearch = useCallback(() => {
    if (!isEmpty(state['quick_search'])) {
      setClearText((prevState) => !prevState);
    }
    if (onSearch) {
      onSearch(trim(state));
    }
  }, [state, onSearch]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleSearch();
      }
    },
    [handleSearch],
  );

  const stylesVariants = useMemo(() => {
    switch (variant) {
      case 'noBorder':
        return noBorderStyleSx;
      default:
        return defaultStyles;
    }
  }, [variant]);

  return (
    <Grid container alignItems="center">
      {map(fields, (field) => {
        return (
          <Fragment key={field.id}>
            {(!field.type || field.type === 'text') && (
              <TextField
                type="search"
                name={field.id}
                variant="outlined"
                value={state[field.id]}
                onChange={handleChange}
                autoFocus={field.autoFocus}
                onKeyDown={handleKeyDown}
                // className={classes.searchBar}
                placeholder={field.placeholder}
                sx={stylesVariants}
                fullWidth
                slotProps={{
                  input: {
                    endAdornment: (
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    ),
                  },
                }}
              />
            )}
          </Fragment>
        );
      })}

      {/* {showFilter && (
        <>
          <div className={classes.filterButton}>
            <IconButton
              aria-haspopup="true"
              onClick={handleFilterClick}
              aria-controls={open ? 'filter-menu' : undefined}
            >
              <FilterListIcon fontSize="small" />
              <ArrowDropDownIcon fontSize="small" />
            </IconButton>
          </div>

          <Menu
            open={open}
            id="filter-menu"
            anchorEl={anchorEl}
            onClose={handleFilterClose}
          >
            {filterOptions.map((option) => (
              <MenuItem key={option} onClick={() => handleFilterSelect(option)}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </>
      )} */}
    </Grid>
  );
}

const defaultStyles = {
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

const noBorderStyleSx = {
  '& .MuiOutlinedInput-root': {
    backgroundColor: colors.white,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};
