import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { date, number, object } from 'yup';
import moment, { type Moment } from 'moment';

import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';

import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid2';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { toTimezone } from 'src/utils/datetime';
import TextInput from 'src/components/TextInput';
import TimeInput from 'src/components/TimeInput';
import { DatePickerField } from 'src/components/DatePickerField';
import { MultiDatePickerField } from 'src/components/MultiDatePickerField';

import messages from './messages';

function shiftSchema(isFlex: boolean) {
  return {
    startTime: date().required(),
    endTime: date().required(),
    date: date().required(),
    ...(isFlex ? { quantity: number().required().integer().min(1) } : {}),
  };
}

export interface ShiftFormikType {
  uuid?: string;
  quantity?: number;
  date: Moment | null;
  endTime: Moment | null;
  startTime: Moment | null;
}

interface ShiftFormParamsType {
  open?: boolean;
  isFlex: boolean;
  multiple?: boolean;
  isCreate?: boolean;
  onClose?: () => void;
  shift?: UCM.ShiftListType | null;
  onSave: (shift: ShiftFormikType) => Promise<void> | void;
}

function hideDiv(divRef: HTMLDivElement | null) {
  if (divRef) divRef.style.display = 'none';
}

export default function ShiftEditPanel({
  open,
  shift,
  isFlex,
  onSave,
  onClose,
  isCreate = true,
  multiple = false,
}: ShiftFormParamsType) {
  const { formatMessage } = useIntl();

  const [isOpen, setOpen] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);

  const shiftFormRef = useRef<HTMLDivElement>(null);
  const addShiftRef = useRef<HTMLDivElement>(null);
  const selectedDates = useRef<Array<Moment> | null>(null);

  const mediumSize = isFlex ? 2.7 : 4;
  const showForm = isOpen || open;

  const initialValues = useMemo(
    () => ({ date: null, endTime: null, startTime: null }),
    [],
  );

  useEffect(() => {
    if (showForm) hideDiv(addShiftRef.current);
    else hideDiv(shiftFormRef.current);
  }, [showForm]);

  useEffect(() => {
    if (shift) {
      formik.setValues({
        uuid: shift.uuid,
        quantity: shift.slotsNumber,
        date: toTimezone(shift.startsAt),
        endTime: toTimezone(shift.endsAt),
        startTime: toTimezone(shift.startsAt),
      });
    }
  }, [shift]);

  const formik = useFormik<ShiftFormikType>({
    initialValues,
    validateOnChange,
    validationSchema: object().shape(shiftSchema(isFlex)),
    onSubmit: () => {},
  });

  const { values, errors } = formik;

  const handleClose = useCallback(() => {
    setValidateOnChange(false);
    selectedDates.current = null;
    formik.resetForm();
    setOpen(false);

    if (onClose) onClose();
  }, [onClose]);

  const handleMultiDateChange = useCallback((dates: Array<Moment> | null) => {
    if (dates) {
      selectedDates.current = dates;
      formik.setFieldValue('date', dates[0]);
    }
  }, []);

  const handleDateChange = useCallback(
    (date: Moment | null) => {
      if (date) {
        const fields = { ...values };
        date.millisecond(0).second(0).minute(0).hour(0);
        fields.date = date;
        const { startTime, endTime } = values;
        if (date.isValid() && startTime) {
          startTime.year(date.year()).month(date.month()).date(date.date());
          fields.startTime = startTime;
        }
        if (date.isValid() && endTime) {
          endTime.year(date.year()).month(date.month()).date(date.date());
          fields.endTime = endTime;
        }
        if (startTime && endTime) {
          const diffInHours = moment
            .duration(endTime.diff(startTime))
            .as('hours');
          if (diffInHours < 0) {
            endTime.add(1, 'days');
            fields.endTime = endTime;
          }
        }
        formik.setValues(fields);
      } else {
        formik.setFieldValue('date', undefined);
      }
    },
    [values],
  );

  const handleStartTime = useCallback(
    (startTime: Moment | null) => {
      if (startTime) {
        const fields = { ...values };
        startTime.millisecond(0).second(0);
        const { date: shiftDate } = values;
        if (shiftDate && shiftDate.isValid()) {
          startTime
            .year(shiftDate.year())
            .month(shiftDate.month())
            .date(shiftDate.date());
        }
        fields.startTime = startTime;
        const { endTime } = values;
        if (endTime && endTime.isValid() && startTime.isValid()) {
          const diffInHours = moment
            .duration(endTime.diff(startTime))
            .as('hours');
          if (diffInHours < 0) {
            endTime.add(1, 'days');
            fields.endTime = endTime;
          }
        }
        formik.setValues(fields);
      } else {
        formik.setFieldValue('startTime', undefined);
      }
    },
    [values],
  );

  const handleEndTime = useCallback(
    (endTime: Moment | null) => {
      if (endTime) {
        const fields = { ...values };
        endTime.millisecond(0).second(0);
        const { date: shiftDate } = values;
        if (shiftDate && shiftDate.isValid()) {
          endTime
            .year(shiftDate.year())
            .month(shiftDate.month())
            .date(shiftDate.date());
        }
        fields.endTime = endTime;
        const { startTime } = values;
        if (startTime && startTime.isValid() && endTime.isValid()) {
          const diffInHours = moment
            .duration(endTime.diff(startTime))
            .as('hours');
          if (diffInHours < 0) {
            endTime.add(1, 'days');
            fields.endTime = endTime;
          }
        }
        formik.setValues(fields);
      } else {
        formik.setFieldValue('endTime', undefined);
      }
    },
    [values],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      formik.handleChange(event);
    },
    [formik],
  );

  const handleAddShift = useCallback(() => {
    setOpen(true);
  }, []);

  const handleSave = useCallback(async () => {
    setValidateOnChange(true);
    const errors = await formik.validateForm();
    const valid = isEmpty(errors);
    if (!valid) return;

    if (multiple && selectedDates.current && !isEmpty(selectedDates.current)) {
      const dates = [...selectedDates.current];

      for (const date of dates) {
        await onSave({ ...values, date });
      }
    } else {
      onSave(values);
    }

    handleClose();
  }, [values, onSave, handleClose]);

  const getTimePickerSlotProps = useCallback(
    memoize((helperText?: string) => ({
      textField: {
        fullWidth: true,
        error: !!helperText,
        helperText: helperText,
        InputProps: { endAdornment: <AccessTimeIcon /> },
        sx: {
          '& .MuiInputAdornment-positionEnd': { marginRight: 1, marginLeft: 0 },
        },
      },
    })),
    [],
  );

  return (
    <Grid container size={12} spacing={2} alignItems="center">
      <Slide in={showForm} mountOnEnter unmountOnExit direction="down">
        <Grid
          ref={shiftFormRef}
          container
          size={12}
          alignItems="center"
          justifyContent="space-between"
          flexWrap="nowrap"
          mt={1}
        >
          <Grid container spacing={1}>
            <Grid size={{ xs: 12, md: mediumSize }}>
              {multiple && isCreate ? (
                <MultiDatePickerField
                  variant="outlined"
                  error={!!errors.date}
                  helperText={errors.date}
                  onChange={handleMultiDateChange}
                  label={formatMessage(messages.dateLabel)}
                />
              ) : (
                <DatePickerField
                  name="date"
                  variant="outlined"
                  value={values.date}
                  disabled={!isCreate}
                  error={!!errors.date}
                  helperText={errors.date}
                  onChange={handleDateChange}
                  label={formatMessage(messages.dateLabel)}
                />
              )}
            </Grid>

            <Grid size={{ xs: 12, md: mediumSize }}>
              <TimeInput
                name="startTime"
                value={values.startTime}
                onChange={handleStartTime}
                label={formatMessage(messages.startTimeLabel)}
                slotProps={getTimePickerSlotProps(errors.startTime)}
                withPicker
              />
            </Grid>

            <Grid size={{ xs: 12, md: mediumSize }}>
              <TimeInput
                name="endTime"
                value={values.endTime}
                onChange={handleEndTime}
                label={formatMessage(messages.endTimeLabel)}
                slotProps={getTimePickerSlotProps(errors.endTime)}
                withPicker
              />
            </Grid>

            {isFlex && (
              <Grid size={{ xs: 12, md: mediumSize }}>
                <TextInput
                  fullWidth
                  type="number"
                  name="quantity"
                  variant="outlined"
                  value={values.quantity}
                  error={!!errors.quantity}
                  helperText={errors.quantity}
                  onChange={handleChange}
                  label={formatMessage(messages.quantityLabel)}
                />
              </Grid>
            )}
          </Grid>

          <Grid container size="auto" spacing={1} justifyContent="center">
            <Button color="primary" onClick={handleSave}>
              {formatMessage(messages.saveShiftButton)}
            </Button>

            <Button variant="outlined" color="primary" onClick={handleClose}>
              {formatMessage(messages.cancelButton)}
            </Button>
          </Grid>
        </Grid>
      </Slide>

      <Fade in={!showForm} mountOnEnter unmountOnExit>
        <Grid
          ref={addShiftRef}
          container
          size={12}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button onClick={handleAddShift}>
            {formatMessage(messages.addButton)}
          </Button>
        </Grid>
      </Fade>
    </Grid>
  );
}
