import React, { useCallback } from 'react';

import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import { IconButton, Stack, Typography } from '@mui/material';

// Import SVGs as React components
import { ReactComponent as FineIcon } from 'src/images/Fine.svg';
import { ReactComponent as SetupIcon } from 'src/images/Setup.svg';
import { ReactComponent as SearchIcon } from 'src/images/Search.svg';
import { ReactComponent as StudentIcon } from 'src/images/Student.svg';
import { ReactComponent as ProjectIcon } from 'src/images/Project.svg';
import { ReactComponent as ContractIcon } from 'src/images/Contract.svg';
import { ReactComponent as TimeTrackingIcon } from 'src/images/TimeTracking.svg';
import { ReactComponent as CancellationIcon } from 'src/images/Cancellation.svg';
import { ReactComponent as VerificationIcon } from 'src/images/Verification.svg';

import useMessenger from 'src/utils/hooks/useMessenger';
import PageLayout, {
  UnderConstructionMessage,
} from 'src/components/PageLayout';

interface MenuItem {
  url: string;
  text: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

export const homeIcons: Array<MenuItem> = [
  { text: 'Projects', icon: ProjectIcon, url: '/projects' },
  { text: 'Cancellations', icon: CancellationIcon, url: 'disabled' },
  { text: 'Students', icon: StudentIcon, url: 'disabled' },
  { text: 'Verification', icon: VerificationIcon, url: 'disabled' },
  { text: 'Contracts', icon: ContractIcon, url: 'disabled' },
  { text: 'Time Tracking', icon: TimeTrackingIcon, url: 'disabled' },
  { text: 'Fines', icon: FineIcon, url: 'disabled' },
  { text: 'Setup', icon: SetupIcon, url: 'disabled' },
  { text: 'Search', icon: SearchIcon, url: 'disabled' },
];

export default function HomePage() {
  const navigate = useNavigate();
  const messenger = useMessenger();

  const handleNavigation = useCallback(
    (text: string, url: string) => {
      if (url === 'disabled') {
        messenger.info(<UnderConstructionMessage text={text} />);
      } else {
        navigate(url);
      }
    },
    [messenger, navigate],
  );

  return (
    <PageLayout title="Home">
      <Stack alignItems="center" justifyItems="center">
        <Grid
          container
          rowSpacing={12}
          pt={4}
          alignItems="center"
          alignContent="center"
        >
          {homeIcons.map(({ text, icon: Icon, url }) => {
            return (
              <Grid key={text} size={{ xs: 12, sm: 6, md: 4 }}>
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  flexDirection="column"
                >
                  <IconButton onClick={() => handleNavigation(text, url)}>
                    <Icon width={80} height={80} />
                  </IconButton>
                  <Typography variant="subtitle1">{text}</Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </PageLayout>
  );
}
