import { useRef } from 'react';

import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import styled from '@mui/material/styles/styled';

import type { DefaultTheme } from '@mui/styles';

interface StyledTabProps {
  label: string;
  badge?: number;
}

const StyledTab = styled((props: StyledTabProps) => {
  const badgeSx = useRef((theme: DefaultTheme) => ({
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.error.dark,
    },
  })).current;

  return (
    <Tab
      disableRipple
      {...props}
      label={
        <Stack
          alignItems={'center'}
          flexDirection={'row'}
          justifyContent={'center'}
        >
          <Box>{props.label}</Box>
          {Boolean(props.badge) && (
            <>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Badge badgeContent={props.badge} sx={badgeSx} />
            </>
          )}
        </Stack>
      }
    />
  );
})(({ theme }) => ({
  margin: 0,
  minWidth: theme.spacing(14),
  padding: theme.spacing(1.5, 2),
  '&.Mui-selected': {
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.primary.dark,
  },
}));

export default StyledTab;
